import React from 'react';
import { Container, Box, FormInput, Button } from '@generationtux/component-library';

export let FormSearch = ({ ...props }) => (
  <Box backgroundColor="grayLighter" my={4} py={[4, 4, 5, 5, 5]}>
    <Container>
      <Box style={{ position: 'relative' }}>
        <FormInput
          {...props}
          mb={0}
          combineLabel
          elementWidth="100%"
          label={props.label ? props.label : 'Search'}
          onClick={null}
        />
        {props.onClick && (
          <Button
            buttonType="info"
            onClick={() => {
              props.onClick();
            }}
            style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }}
            mr={3}
          >
            Search
          </Button>
        )}
      </Box>
    </Container>
  </Box>
);
