import { Bundle, Product } from '../types';
import { fetchMiddleware } from './fetchMiddleware';

export const getProduct = async (id: number | string) =>
  await fetchMiddleware(`${process.env.REACT_APP_PRODUCTS_URL}/v2/products/${id}?with=blockoutDates,media,swatch`);

export const createProduct = async (product: Product) =>
  await fetchMiddleware(`${process.env.REACT_APP_PRODUCTS_URL}/v3/products`, {
    method: 'POST',
    body: JSON.stringify(product),
  });

export const updateProduct = async (id: number | string, product: Product) =>
  await fetchMiddleware(`${process.env.REACT_APP_PRODUCTS_URL}/v2/products/${id}/update`, {
    method: 'PUT',
    body: JSON.stringify(product),
  });

export const setProductStatus = async (id: number | string, isActive: boolean) =>
  await fetchMiddleware(`${process.env.REACT_APP_PRODUCTS_URL}/v2/products/${id}/setActiveStatus`, {
    method: 'POST',
    body: JSON.stringify({ is_active: isActive }),
  });

export const searchProducts = async (search: string) =>
  await fetchMiddleware(`${process.env.REACT_APP_PRODUCTS_URL}/v2/products/search?q=${search}`);

export const searchMedia = async (search: string) =>
  await fetchMiddleware(`${process.env.REACT_APP_PRODUCTS_URL}/v1/media/search?q=${search}`);

export const getBundles = async () =>
  await fetchMiddleware(
    `${process.env.REACT_APP_PRODUCTS_URL}/v2/bundles?with=media,products&is_active=true&category=preconfigured`
  );

export const getStyledLooks = async () =>
  await fetchMiddleware(
    `${process.env.REACT_APP_PRODUCTS_URL}/v2/bundles?with=media,products,preconfigured&is_active=true&category=look`
  );

export const getSwatches = async () =>
  await fetchMiddleware(
    `${process.env.REACT_APP_PRODUCTS_URL}/v2/products?with=media&is_active=true&category=Swatch (accessory),Swatch (suit/tux)`
  );

export const getProductList = async (category: string) =>
  await fetchMiddleware(
    `${process.env.REACT_APP_PRODUCTS_URL}/v2/products?with=media&displayable=true&category=${category}`
  );

export const updateBundleDisplayIndices = async (bundles: Bundle[]) =>
  await fetchMiddleware(`${process.env.REACT_APP_PRODUCTS_URL}/v2/bundles/displayIndex`, {
    method: 'POST',
    body: JSON.stringify(bundles),
  });

export const updateProductsDisplayIndices = async (products: Product[]) =>
  await fetchMiddleware(`${process.env.REACT_APP_PRODUCTS_URL}/v2/products/displayIndex`, {
    method: 'POST',
    body: JSON.stringify(products),
  });
