import React from 'react';
import { Box, Button, Trashcan, Text } from '@generationtux/component-library';

interface RemovableBlockProps {
  label?: string;
  removeFunction?: () => void;
}

export const RemovableBlock = ({ label, removeFunction }: RemovableBlockProps) => (
  <Box display="inline-flex" alignItems="stretch" backgroundColor="grayLighter" mt={2}>
    <Text px={3} py={2} as="span" style={{ flexGrow: 1 }}>
      {label ? label : 'Placeholder'}
    </Text>

    <Button
      buttonType="danger"
      size="sm"
      buttonIcon={<Trashcan />}
      onClick={() => {
        removeFunction ? removeFunction() : undefined;
      }}
    ></Button>
  </Box>
);
