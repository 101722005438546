import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import MediaItem from './MediaItem';
import { Box, Header, Text } from '@generationtux/component-library';

import { Media } from '../../../types';

interface Props {
  media: Media[];
  handleDelete: (media: Media) => void;
}

export default SortableContainer(({ media, handleDelete }: Props) => (
  <Box mb={5}>
    <Header type={3} size={4} mb={2} textTransform="none">
      Media List
    </Header>
    <Text size="sm" mb={3} textColor="grayDark">
      Images will display on product/bundle pages in the following order:{' '}
      <Text as="span" textColor="grayDarker" size="sm">
        PDP_Hero
      </Text>
      ,
      <Text as="span" textColor="grayDarker" size="sm">
        PDP_Alt1
      </Text>
      ,
      <Text as="span" textColor="grayDarker" size="sm">
        PDP_Alt2
      </Text>
      ,
      <Text as="span" textColor="grayDarker" size="sm">
        PDP_Alt3
      </Text>
      …
      <Text as="span" textColor="grayDarker" size="sm">
        PDP_Alt12
      </Text>
    </Text>

    {media.length > 0 &&
      media.map((media, i) => <MediaItem media={media} index={i} key={i} handleDelete={handleDelete} />)}
  </Box>
));
