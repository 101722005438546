import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Anchor,
  Row,
  Column,
  FormSelect,
  Box,
  Button,
  Container,
  Header,
  Plus,
  FormInput,
  Line,
  Message,
  Spinner,
  Text,
} from '@generationtux/component-library';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Bundle as BundleType, BundleMedia, BlockoutDate, Item, Product } from '../../types';

import { getBundles, getBundle, createBundle, updateBundle } from '../../services/BundlesApi';
import { searchProducts, getSwatches } from '../../services/ProductsApi';

import { buildMedia, getErrorMessage, getResponseBody } from '../../helpers/Helpers';
import { calcDisplayIndex } from '../../helpers/Helpers';

import AddMedia from '../shared/Media/AddMedia';
import MediaList from '../shared/Media/MediaList';
import BlockoutDateRangePicker from '../shared/BlockoutDateRangePicker';

import Breadcrumbs from '../Breadcrumbs';
import BundleInfo from './BundleInfo';
import { RemovableBlock } from '../shared/RemovableBlock';

/**
 * This function is used for bundle creation requests since null values will cause the form validator
 * to fail, and update requests allow null values for things like dissociating swatch relationships
 */
const cleanNullValues = (bundle: BundleType) => {
  return Object.entries(bundle).reduce(
    (newBundle, entry) => {
      const [key, value] = entry;

      if (value !== null) {
        return {
          ...newBundle,
          [key]: value,
        };
      }

      return newBundle;
    },
    {} as BundleType
  );
};

interface Props extends RouteComponentProps<{ id?: string }> {
  creating: boolean;
}

export interface State {
  loading: boolean;
  loadingError: boolean;
  bundle: BundleType;
  error: boolean;
  saving: boolean;
  saveErrorMessage: string;
  success: boolean;
  addingProduct: boolean;
  addingBundle: boolean;
  addingRetailBundle: boolean;
  searchError: boolean;
  productSearch: string;
  productSearchResults: Item[];
  bundleOptions: Item[];
  swatchSearch: string;
  swatches: Item[];
  swatchSearchResults: Item[];
  media: BundleMedia[];
  retailBundles: Item[];
  retailBundleSearch: string;
  retailBundleSearchResults: Item[];
  copied: boolean;
}

class Bundle extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      loadingError: false,
      copied: false,
      bundle: {
        id: 0,
        sku: '',
        type: '',
        category: '',
        display_name: '',
        short_description: '',
        url_slug: '',
        cost: '',
        is_active: false,
        displayable: false,
        is_retail: false,
        available_in_boys: false,
        available_in_slim: false,
        rental_terms: '',
        color: '',
        details: [],
        products: [],
        preconfigured: [],
        media: [],
        swatch: null,
        blockout_dates: [],
        retail_bundle: null,
      },
      error: false,
      saving: false,
      saveErrorMessage: '',
      success: false,
      addingProduct: false,
      addingBundle: false,
      addingRetailBundle: false,
      productSearch: '',
      retailBundleSearch: '',
      swatchSearch: '',
      searchError: false,
      productSearchResults: [],
      bundleOptions: [],
      swatchSearchResults: [],
      swatches: [],
      media: [],
      retailBundles: [],
      retailBundleSearchResults: [],
    };
  }

  async componentDidMount() {
    if (!this.props.creating) {
      await this.getBundle();
    }

    try {
      const swatchesRes = await getSwatches();
      const bundlesRes = await getBundles();

      if (swatchesRes.status >= 400)
        throw new Error(`Bad response status (${swatchesRes.statusText}) loading swatches.`);

      if (bundlesRes.status >= 400) throw new Error(`Bad response status (${bundlesRes.statusText}) loading bundles.`);

      const swatches = await swatchesRes.json();
      const bundles = await bundlesRes.json();

      const retailBundles = bundles.filter(b => b.is_retail);
      const bundleOptions = bundles.filter(b => b.is_active && b.category === 'preconfigured');

      this.setState({ swatches, retailBundles, bundleOptions });
    } catch (e) {
      this.setState({ loadingError: true });
      console.error(e);
    }

    this.setState({ loading: false });
  }

  getBundle = async () => {
    const { id } = this.props.match.params;
    try {
      const bundlesRes = await getBundle(id!);

      if (bundlesRes.status >= 400) throw new Error(`Bad response status (${bundlesRes.statusText}) loading bundle.`);

      const bundle = await bundlesRes.json();
      const media = bundle.media!;

      this.setState({
        bundle: {
          ...bundle,
          products: bundle.products!.map((p, i) => ({ ...p, display_index: i })),
        },
        media,
      });
    } catch (e) {
      this.setState({ loadingError: true });
      console.error(e);
    }
  };

  handleChange = (field: string, value: string) =>
    this.setState(state => ({
      bundle: {
        ...state.bundle,
        [field]: value,
      },
    }));

  // Details
  addDetailInput = () =>
    this.setState({
      bundle: {
        ...this.state.bundle,
        details: this.state.bundle.details!.concat({ detail: '' }),
      },
    });

  updateDetail = (index: number, value: string) => {
    const details =
      this.state.bundle.details &&
      this.state.bundle.details.map((detail, i) => {
        if (index === i) return { ...detail, detail: value };
        return detail;
      });

    this.setState({
      bundle: {
        ...this.state.bundle,
        details,
      },
    });
  };

  removeDetail = (index: number) => {
    this.setState({
      bundle: {
        ...this.state.bundle,
        details: this.state.bundle.details!.filter((_, i) => index !== i),
      },
    });
  };

  // Blockout Dates
  addBlockoutDate = (blockoutDate: BlockoutDate) =>
    this.setState(state => ({
      bundle: {
        ...state.bundle,
        blockout_dates: state!.bundle!.blockout_dates!.concat(blockoutDate),
      },
    }));

  removeBlockoutDate = (index: number) => {
    const blockoutDates = this.state.bundle.blockout_dates!.filter((date, i) => index !== i);

    this.setState(state => ({
      bundle: { ...state.bundle, blockout_dates: blockoutDates },
    }));
  };

  // Swatches
  addSwatch = (swatch: Item) =>
    this.setState({
      bundle: {
        ...this.state.bundle,
        swatch,
      },
      swatchSearch: '',
      swatchSearchResults: [],
    });

  removeSwatch = () => {
    this.setState({
      bundle: {
        ...this.state.bundle,
        swatch: null,
      },
    });
  };

  handleSwatchSearchChange = (value: string) =>
    this.setState(() => ({
      swatchSearch: value,
      swatchSearchResults: this.state.swatches!.filter(
        (s: Item) =>
          s.display_name &&
          (s.display_name.toLowerCase().includes(value.toLowerCase()) ||
            s.sku.toLowerCase().includes(value.toLowerCase()))
      ),
    }));

  // Products
  addProduct = (product: Item) => {
    this.setState({
      bundle: {
        ...this.state.bundle,
        products: this.state.bundle.products!.concat(product as Product),
      },
      productSearchResults: [],
      addingProduct: false,
      productSearch: '',
    });
  };

  removeProduct = (index: Number) =>
    this.setState({
      bundle: {
        ...this.state.bundle,
        products: this.state.bundle.products!.filter((_, i) => index !== i),
      },
    });

  handleProductSearchKeyPress = async (e: React.KeyboardEvent) => {
    this.setState(() => ({
      searchError: false,
    }));

    if (e.key === 'Enter') {
      try {
        const productsRes = await searchProducts(this.state.productSearch);

        if (productsRes.status >= 400)
          throw new Error(`Bad response status (${productsRes.statusText}) loading products.`);

        const data = await productsRes.json();

        this.setState(() => ({
          productSearch: '',
          productSearchResults: data.filter((product: Item) => product.is_active),
        }));
      } catch (e) {
        this.setState(() => ({
          searchError: true,
        }));
        console.error(e);
      }
    }
  };

  handleProductSearchChange = (value: string) => this.setState(() => ({ productSearch: value }));

  // Bundles
  addBundle = (bundle: Item) =>
    this.setState({
      bundle: {
        ...this.state.bundle,
        preconfigured: this.state.bundle.preconfigured! = [bundle],
      },
      addingBundle: false,
    });

  removeBundle = (index: number) =>
    this.setState({
      bundle: {
        ...this.state.bundle,
        preconfigured: this.state.bundle.preconfigured!.filter((bundles, i) => index !== i),
      },
    });

  // Media
  handleNewMedia = (newMedia: BundleMedia) =>
    this.setState(state => ({
      media: state.media.concat(newMedia),
    }));

  handleRemoveMedia = (media: BundleMedia) => {
    const updatedMedia = new Set(this.state.media);
    updatedMedia.delete(media);

    this.setState(() => ({
      media: Array.from(updatedMedia),
    }));
  };

  // Retail Bundles
  handleRetailBundleSearchChange = (value: string) =>
    this.setState(() => ({
      retailBundleSearch: value,
    }));

  handleRetailBundleSearchKeyPress = async (e: React.KeyboardEvent) => {
    this.setState(() => ({
      searchError: false,
    }));

    if (e.key === 'Enter') {
      const retailBundles = this.state.retailBundles.filter(
        bundle =>
          bundle.display_name && bundle.display_name.toLowerCase().includes(this.state.retailBundleSearch.toLowerCase())
      );
      this.setState(() => ({
        retailBundleSearch: '',
        retailBundleSearchResults: retailBundles,
      }));
    }

    return null;
  };

  addRetailBundle = (retailBundle: Item) =>
    this.setState({
      addingRetailBundle: false,
      bundle: {
        ...this.state.bundle,
        retail_bundle: retailBundle,
      },
      retailBundleSearchResults: [],
      retailBundleSearch: '',
    });

  removeRetailBundle = () =>
    this.setState({
      bundle: {
        ...this.state.bundle,
        retail_bundle: null,
      },
    });

  searchReset = () => {
    this.setState(() => ({
      productSearch: '',

      retailBundleSearch: '',
      swatchSearch: '',
      productSearchResults: [],
      swatchSearchResults: [],

      retailBundleSearchResults: [],
    }));
  };

  handleSaveSuccess = (data: Item) => {
    this.setState(state => ({
      saving: false,
      success: true,
      error: false,
      saveErrorMessage: '',
      bundle: {
        ...state.bundle,
        ...(data.id !== state.bundle.id && { id: data.id }),
      },
    }));

    setTimeout(() => {
      this.setState(() => ({ success: false }));
    }, 1000);

    this.props.history.push(`/bundles/${data.id}`);
  };

  handleSaveError = (saveErrorMessage: string) => {
    this.setState({
      error: true,
      saving: false,
      saveErrorMessage,
    });
  };

  saveBundle = () => {
    const { creating } = this.props;

    this.setState(
      state => ({
        saving: true,
        bundle: {
          ...state.bundle,
          media: buildMedia(state.media),
        },
      }),
      async () => {
        try {
          const cb = creating ? createBundle : updateBundle;

          const bundle = creating ? cleanNullValues(this.state.bundle) : this.state.bundle;

          const bundleRes = await cb(bundle);

          if (bundleRes.status >= 400) {
            const verb = creating ? 'creating' : 'updating';
            const errorBody = await getResponseBody(bundleRes);

            throw new Error(
              `Bad response status (${bundleRes.statusText}) ${verb} bundle: ${getErrorMessage(errorBody)}`
            );
          }

          const data = await bundleRes.json();

          return this.handleSaveSuccess(data);
        } catch (e) {
          console.error(e);
          return this.handleSaveError(getErrorMessage(e));
        }
      }
    );
  };

  getAPIErrorMessage = () => {
    if (!this.state.saveErrorMessage) {
      return 'There was an error saving the bundle.';
    }

    return this.state.saveErrorMessage;
  };

  render() {
    const { creating } = this.props;
    const {
      loading,
      loadingError,
      bundle,
      saving,
      success,
      swatchSearch,
      swatchSearchResults,
      productSearch,
      searchError,
      productSearchResults,
      bundleOptions,
      media,
      error,
      retailBundles,
    } = this.state;

    if (loading) return <Spinner />;

    const searchReset = () => (
      <div>
        <Text
          size="sm"
          textColor="danger"
          style={{ cursor: 'pointer', display: 'inline-block' }}
          mt={2}
          mb={3}
          onClick={() => {
            this.searchReset();
          }}
        >
          Clear Search Results
        </Text>
      </div>
    );

    const lookBuilderUrl = `${process.env.REACT_APP_PUBLIC_APP_URL}/customize?bundle_ids=${bundle.preconfigured &&
      bundle.preconfigured.length > 0 &&
      bundle.preconfigured[0].id}&sidecar=list&product_skus=${bundle.products &&
      bundle.products.map(x => x.sku).join('%2C')}`;

    const isRecommendedLook = bundle.category === 'look';

    return (
      <Container>
        {loadingError && (
          <Message
            shadowLevel={0}
            messageType="error"
            msg={'An error occurred loading the bundle. Please try again.'}
          />
        )}

        <Box mb={4}>
          <Breadcrumbs />
        </Box>

        <Row justifyContent="space-between" alignItems="flex-start" flexWrap="wrap" flexDirection="row-reverse">
          <Column
            column={12}
            columnSm={4}
            columnMd={5}
            columnLg={4}
            style={{ position: 'sticky', top: 32, zIndex: 1000 }}
          >
            <Box backgroundColor="white" shadowLevel={3} p={[3, 3, 4, 4, 4, 4]} mb={4}>
              <Header type={1} size={3} mb={3} textTransform="none">
                {bundle.display_name}
              </Header>

              <Box display="flex" flexDirection="column" mt={3}>
                <Button buttonType="info" style={{ width: '100%' }} onClick={() => this.saveBundle()}>
                  {saving ? 'Saving...' : 'Save Changes'}
                </Button>

                {success && <Message mt={3} shadowLevel={0} messageType="success" msg={'Bundle saved!'} />}
                {error && <Message mt={3} shadowLevel={0} messageType="error" msg={this.getAPIErrorMessage()} />}
              </Box>
            </Box>
          </Column>

          <Column column={12} columnSm={8} columnMd={6} columnLg={7}>
            <Box>
              <BundleInfo
                updateDetail={this.updateDetail}
                removeDetail={this.removeDetail}
                addDetailInput={this.addDetailInput}
                bundle={bundle}
                creating={creating}
                handleChange={this.handleChange}
              />
              <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />
              <Box>
                <Header type={2} display="block" as="label" htmlFor="product_blockout_dates" mb={3}>
                  Blockout Dates
                </Header>

                <BlockoutDateRangePicker
                  addBlockoutDate={this.addBlockoutDate}
                  removeBlockoutDate={this.removeBlockoutDate}
                  blockoutDates={bundle.blockout_dates}
                />
              </Box>
              <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />
              <Header type={2}>{bundle.swatch ? 'Swatch' : 'Add a Swatch'}</Header>
              {!bundle.swatch && (
                <>
                  <FormInput
                    name="swatchSearch"
                    value={swatchSearch}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleSwatchSearchChange(e.target.value)}
                    placeholder="Search"
                    label="Search Swatches"
                    description="Search for and select a swatch to associate with this product."
                    mt={3}
                  />
                  {swatchSearchResults.length > 0 && searchReset()}

                  <Box>
                    {swatchSearchResults.map((swatch: Item) => (
                      <Button
                        key={swatch.id}
                        onClick={() => {
                          this.addSwatch(swatch);
                        }}
                        buttonType="info"
                        buttonIcon={<Plus />}
                        buttonIconPosition="right"
                        mr={2}
                        mb={2}
                      >
                        {swatch.sku} - {swatch.display_name}
                      </Button>
                    ))}
                  </Box>
                </>
              )}
              {!!bundle.swatch && (
                <Box key={bundle.swatch.id}>
                  <RemovableBlock
                    label={bundle.swatch.display_name}
                    removeFunction={() => {
                      this.removeSwatch();
                    }}
                  />
                </Box>
              )}
              <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />
              <Box>
                <Header type={2}>Add Products</Header>
              </Box>
              <Box>
                <FormInput
                  mt={3}
                  label="Product Search"
                  description="Search for and add the products that make up this recommended look."
                  name="search"
                  value={productSearch}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleProductSearchChange(e.target.value)}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => this.handleProductSearchKeyPress(e)}
                  placeholder="Search"
                />

                {this.state.productSearchResults.length > 0 && searchReset()}

                {searchError && (
                  <Box my={2}>
                    <Message messageType="error" msg={'There was an error loading products'} />
                  </Box>
                )}

                <Box>
                  {productSearchResults.map((product: Item) => (
                    <Button
                      key={product.id}
                      buttonType="info"
                      buttonIcon={<Plus />}
                      buttonIconPosition="right"
                      mr={2}
                      mb={2}
                      onClick={() => this.addProduct(product)}
                    >
                      {product.sku} - {product.display_name}
                    </Button>
                  ))}
                </Box>
              </Box>

              <Box display="inline-flex" flexDirection="column" mt={3}>
                {bundle.products &&
                  bundle.products.map((product: Item, i: number) => (
                    <RemovableBlock
                      key={product.id}
                      label={product.display_name}
                      removeFunction={() => {
                        this.removeProduct(i);
                      }}
                    />
                  ))}
              </Box>

              {isRecommendedLook && (
                <Box mt={4}>
                  <Header type={3} mb={2}>
                    Example Look Builder Url
                  </Header>

                  <Text size="sm" mb={2}>
                    You can use this as a starting point to create a Permalink. DO NOT use or share this url. You need
                    to{' '}
                    <Anchor size="sm" href={'/permalinks'}>
                      make a permalink
                    </Anchor>{' '}
                    with it.
                  </Text>

                  <Box display="flex">
                    <Box backgroundColor="grayLighter" p={3}>
                      <Text size="xs" style={{ wordBreak: 'break-all' }} textColor="grayDarker">
                        {lookBuilderUrl}
                      </Text>
                    </Box>
                    <CopyToClipboard text={lookBuilderUrl}>
                      <Button buttonType="info" size="sm" onClick={() => this.setState({ copied: true })}>
                        {this.state.copied ? `Copied!` : `Copy Url`}
                      </Button>
                    </CopyToClipboard>
                  </Box>
                </Box>
              )}

              <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />
              {(isRecommendedLook || !bundle.is_retail) && <Header type={2}>Bundle Associations</Header>}
              {isRecommendedLook && (
                <>
                  <FormSelect
                    mt={4}
                    label="Rental"
                    description="Select which RENTAL suit/tux you'd like to associate with this bundle."
                    combineLabel
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      bundleOptions.every((b: Item) => {
                        if (b.display_name === e.target.value) {
                          this.addBundle(b);
                          return false;
                        } else if (e.target.value === '') {
                          this.removeBundle(0);
                          return false;
                        } else {
                          return true;
                        }
                      });
                    }}
                    name="bundle"
                    value={
                      bundle.preconfigured && bundle.preconfigured.length > 0 && bundle.preconfigured[0].display_name
                    }
                  >
                    <option value="">None</option>
                    {bundleOptions.map((b: Item) => (
                      <option key={b.id} value={b.display_name}>
                        {b.display_name}
                      </option>
                    ))}
                  </FormSelect>
                </>
              )}
              {!bundle.is_retail && (
                <>
                  <Box>
                    <FormSelect
                      mt={4}
                      label="Retail"
                      description="Select which RETAIL suit/tux you'd like to associate with this bundle."
                      combineLabel
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        retailBundles.every((b: Item) => {
                          if (b.display_name === e.target.value) {
                            this.addRetailBundle(b);
                            return false;
                          } else if (e.target.value === '') {
                            this.removeRetailBundle();
                            return false;
                          } else {
                            return true;
                          }
                        });
                      }}
                      name="retail_bundle"
                      value={!!bundle.retail_bundle && bundle.retail_bundle.display_name}
                    >
                      <option value="">None</option>
                      {retailBundles.map((b: Item) => (
                        <option key={b.id} value={b.display_name} disabled={!b.is_active}>
                          {b.display_name}
                        </option>
                      ))}
                    </FormSelect>
                  </Box>
                </>
              )}

              {!isRecommendedLook && (
                <>
                  <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />
                  <AddMedia minDisplayIndex={calcDisplayIndex(media)} handleNewMedia={this.handleNewMedia} />
                  <MediaList media={media} handleDelete={this.handleRemoveMedia} axis="y" lockAxis="y" />
                  <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />
                </>
              )}
            </Box>
          </Column>
        </Row>
      </Container>
    );
  }
}

export default Bundle;
