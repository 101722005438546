import React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router';
import { Text, Row, Box, Column, Header, Container, Img, Anchor } from '@generationtux/component-library';

import Breadcrumbs from '../Breadcrumbs';

interface CollectionItemProps {
  href: string;
  title: string;
  imgUrl: string;
  imgAlt: string;
}

const CollectionItem = ({ href, title, imgUrl, imgAlt }: CollectionItemProps) => (
  <Anchor style={{ cursor: 'pointer' }} href={href}>
    <Box backgroundColor="white" shadowLevel={3} p={3}>
      <Box mb={2} justify="flex" justifyContent="center" display="flex">
        {title}
      </Box>
      <Box justify="flex" justifyContent="center" display="flex">
        <Img maxWidth={200} src={imgUrl} alt={imgAlt} />
      </Box>
    </Box>
  </Anchor>
);

const Collections = ({ history }: RouteComponentProps) => (
  <Container>
    <Row mt={3}>
      <Column columnSm={12}>
        <Header type={1}>Collections</Header>
        <Text>Oranize collections by category.</Text>
      </Column>
    </Row>

    <Row mt={2}>
      <Column columnSm={12} mb={4}>
        <Breadcrumbs />
      </Column>
    </Row>

    <Box display="grid" style={{ gridTemplateColumns: 'repeat(4,1fr)', gap: 32 }}>
      <CollectionItem
        href="/collections/tuxedos-and-suits"
        title="Suits / Tuxedos"
        imgUrl="https://gentux.imgix.net/1523286901_130069BLK-NotchSuit-FrontA.jpg?auto=format&fit=crop&fm=jpg&q=75"
        imgAlt="Suits and Tuxes"
      />

      <CollectionItem
        href="/collections/shirts"
        title="Shirts"
        imgUrl="https://gentux.imgix.net/1539273948_181010-154803-shirt-white-spread.jpg"
        imgAlt="Shirt"
      />

      <CollectionItem
        href="/collections/vests-and-cummerbunds"
        title="Vests / Cummerbunds"
        imgUrl="https://gentux.imgix.net/1539341264_20181009-150631-430953MYB-Vest_Cummerbund-MysticBlue_F.jpg"
        imgAlt="Vest"
      />

      <CollectionItem
        href="/collections/ties"
        title="Ties"
        imgUrl="https://gentux.imgix.net/1557315253_layflat-solid-BRZ-bronze-long_tie-190312180615.jpg"
        imgAlt="Long Tie"
      />

      <CollectionItem
        href="/collections/shoes-and-socks"
        title="Shoes / Socks"
        imgUrl="https://gentux.imgix.net/1539360706_20181009-150631-A10184BMT_Shoe_Matte_Black_PVC.jpg"
        imgAlt="Shoes"
      />

      <CollectionItem
        href="/collections/accessories"
        title="Accessories"
        imgUrl="https://gentux.imgix.net/1583351825_product_render-cufflinks-original-gold-black-2020-03-04_12-10-00.jpg"
        imgAlt="Accessories"
      />

      <CollectionItem
        href="/collections/accessory-swatches"
        title="Accessory Swatches"
        imgUrl="https://gentux.imgix.net/1523286867_ruby_swatch.jpg"
        imgAlt="Swatches"
      />

      <CollectionItem
        href="/collections/tux-suit-swatches"
        title="Tux / Suit Swatches"
        imgUrl="https://gentux.imgix.net/1523286874_Midnight_Blue_Tux_swatch.jpg"
        imgAlt="Swatches"
      />
    </Box>
  </Container>
);

export default withRouter(Collections);
