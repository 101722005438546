import React, { Component } from 'react';
import { Text, Box, FormInput, Message } from '@generationtux/component-library';

import { Media } from '../../../types';
import { searchMedia } from '../../../services/ProductsApi';
import MediaTable from './MediaTable';

interface Props {
  handleNewMedia: (media: Media) => void;
}

interface State {
  media: Media[];
  search: string;
  error: boolean;
}

class SearchMedia extends Component<Props, State> {
  state = {
    media: [],
    search: '',
    error: false,
  };

  handleChange = (value: string) => this.setState({ search: value });

  handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      try {
        const productsRes = await searchMedia(this.state.search);

        if (productsRes.status && productsRes.status !== 200 && productsRes.status !== 201)
          throw new Error(`Bad response status (${productsRes.statusText}) loading bundles.`);

        const data = await productsRes.json();

        this.setState(() => ({
          search: '',
          media: data,
        }));
      } catch (e) {
        this.setState({ error: true });
        console.error(e);
      }
    }
  };

  handleNewMedia = (media: Media) => {
    this.props.handleNewMedia({
      description: media.description,
      created_by: media.created_by,
      hosted_content_id: media.hosted_content_store_id,
      url: media.url,
    });

    this.setState(() => ({
      media: [],
    }));
  };

  render() {
    const { search, media, error } = this.state;

    return (
      <>
        <FormInput
          name="search"
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e.target.value)}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => this.handleKeyPress(e)}
          placeholder="Search"
          label="Search Media"
          description="Search for media to add to this item."
          errorMessage={error ? 'Error loading media.' : ''}
        />

        {this.state.media.length > 0 && (
          <div>
            <Text
              size="sm"
              textColor="danger"
              style={{ cursor: 'pointer', display: 'inline-block' }}
              mt={2}
              mb={3}
              onClick={() => {
                this.setState({ media: [], search: '' });
              }}
            >
              Clear Search Results
            </Text>
          </div>
        )}

        {media.length > 0 && <MediaTable media={media} onRowClick={this.handleNewMedia} />}
      </>
    );
  }
}

export default SearchMedia;
