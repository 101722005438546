import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Box,
  Button,
  Container,
  Line,
  Message,
  Spinner,
  Text,
  Trashcan,
  Plus,
  Undo,
  FormInput,
  space,
} from '@generationtux/component-library';

import { Permalink } from '../../types';
import { getPermalinksBySearch, updateAllPermalinkSkus } from '../../services/MediaApi';

import PermalinksTable from './PermalinksTable';
import PermalinksHeader from './PermalinksHeader';
import { FormSearch } from '../FormSearch';

interface Props extends RouteComponentProps<{}> {}

interface State {
  search: string;
  loadingLinks: boolean;
  serverError: string;
  permalinks: Permalink[];
  showReplace: boolean;
  newSku: string;
  success: boolean;
  successCount: number;
  confirmSelect: boolean;
}

class Permalinks extends Component<Props, State> {
  state = {
    search: '',
    loadingLinks: false,
    serverError: '',
    permalinks: [],
    showReplace: false,
    newSku: '',
    success: false,
    successCount: 0,
    confirmSelect: false,
  };

  handleSearchChange = (search: string) => this.setState(() => ({ search }));

  clearSearch = () => this.setState(() => ({ search: '', permalinks: [] }));

  handleSearchKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  // search permalinks by sku
  handleSearch = async () => {
    this.setState(() => ({
      loadingLinks: true,
      success: false,
      successCount: 0,
      serverError: '',
    }));

    try {
      const response = await getPermalinksBySearch(this.state.search);

      if (response.status && response.status !== 200 && response.status !== 201)
        throw new Error(`Bad response status (${response.statusText}) loading permalinks.`);

      const data = await response.json();

      if (data.data && data.data.PermalinksBySearch) {
        this.setState(() => ({
          permalinks: data.data.PermalinksBySearch,
        }));
      } else {
        this.setState(() => ({
          serverError: 'There was a problem loading permalinks.',
        }));
      }
    } catch (e) {
      this.setState(() => ({
        serverError: 'There was a problem loading permalinks.',
      }));
      console.error(e);
    }

    this.setState(() => ({ loadingLinks: false }));
  };

  handleBatchUpdate = async () => {
    this.setState(() => ({
      serverError: '',
      success: false,
      successCount: 0,
    }));

    const { search, newSku } = this.state;

    try {
      const response = await updateAllPermalinkSkus(search, newSku);

      if (response.status && response.status !== 200 && response.status !== 201)
        throw new Error(`Bad response status (${response.statusText}) updating permalinks.`);

      const data = await response.json();

      if (data.data && data.data && data.data.UpdateAllPermalinkSkus) {
        this.setState(() => ({
          success: true,
          successCount: data.data.UpdateAllPermalinkSkus.length,
        }));
      } else {
        this.setState(() => ({
          serverError: 'There was a problem batch updating permalinks.',
        }));
      }
    } catch (e) {
      this.setState(() => ({
        serverError: 'There was a problem batch updating permalinks.',
      }));
      console.error(e);
    }
  };

  render() {
    const {
      search,
      newSku,
      serverError,
      loadingLinks,
      permalinks,
      showReplace,
      success,
      successCount,
      confirmSelect,
    } = this.state;

    const hasPermalinks = permalinks && permalinks.length > 0;

    return (
      <>
        <Container>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <PermalinksHeader />

            <Button
              buttonType="primary"
              onClick={() => this.props.history.push('/permalinks/create')}
              buttonIcon={<Plus />}
              size="md"
            >
              New Permalink
            </Button>
          </Box>
        </Container>

        <FormSearch
          darkMode
          label="Search by shortcode, or original url."
          name="search"
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleSearchChange(e.target.value)}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => this.handleSearchKeyPress(e)}
          placeholder="Search"
          elementWidth="100%"
          mb={3}
          onClick={() => this.handleSearch()}
        />

        <Container>
          <Box display="flex" mb={4}>
            {hasPermalinks && (
              <Button
                buttonType="info"
                outline
                size="sm"
                buttonIcon={<Trashcan color="textPrimary" />}
                buttonIconPosition="right"
                onClick={() => this.clearSearch()}
                mr={2}
              >
                Clear Search
              </Button>
            )}
          </Box>

          {hasPermalinks && (
            <>
              <Box my={5}>
                {showReplace && (
                  <Box display="inline-flex" flexDirection="column">
                    <FormInput
                      label="Batch Replace"
                      description={
                        <Box p={3} pt={0}>
                          <Text textColor="grayDark">
                            Finds the searched string{' '}
                            <Text as="span" textColor="grayDarker">
                              "{this.state.search}"
                            </Text>{' '}
                            in the original url and replaces it with the text entered below.
                            <Text textColor="danger">This can replace lots of data. Please be careful!</Text>
                          </Text>
                        </Box>
                      }
                      name="newSku"
                      placeholder="Replacement text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ newSku: e.target.value })}
                      mb={3}
                    />

                    <Box display="grid" style={{ gridTemplateColumns: '1fr 1fr', gap: space[3] }}>
                      <Button buttonType="default" onClick={() => this.setState({ showReplace: false })}>
                        Cancel
                      </Button>

                      {!confirmSelect && (
                        <Button
                          buttonType="info"
                          onClick={() => {
                            this.setState({ confirmSelect: true });
                            if (window.confirm(`Replace all occurrences of ${search} with ${newSku}?`))
                              this.handleBatchUpdate();
                            return this.setState({ confirmSelect: false });
                          }}
                        >
                          Replace
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Text textColor="grayDark">
                  Found{' '}
                  <Text as="strong" textColor="grayDarker">
                    {this.state.permalinks.length}
                  </Text>{' '}
                  results:
                </Text>

                {!showReplace && (
                  <Button
                    buttonType="primary"
                    outline
                    size="sm"
                    buttonIcon={<Undo />}
                    buttonIconPosition="right"
                    onClick={() => this.setState(() => ({ showReplace: true }))}
                  >
                    Batch Replace
                  </Button>
                )}
              </Box>

              <Line my={4} lineColor="grayLighter" />
            </>
          )}

          <Box>
            {serverError && <Message messageType="error" msg={serverError} />}

            {loadingLinks && <Spinner type="minimal" />}

            {!loadingLinks && success && (
              <Message messageType="success" msg={`Replaced ${successCount} occurrences with ${newSku}.`} />
            )}

            {hasPermalinks && <PermalinksTable permalinks={permalinks} searchStr={this.state.search} />}
          </Box>
        </Container>
      </>
    );
  }
}

export default Permalinks;
