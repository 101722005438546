import React from 'react';
import { Box, FormInput, FormSelect, Button, space } from '@generationtux/component-library';
import styled from 'styled-components';
import { Media } from '../../../types';

const BoxGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 1px;
`;

const mediaLabels = [
  'JACKET_FRONT_LOOK',
  'Other_Way',
  'PDP_Alt1',
  'PDP_Alt2',
  'PDP_Alt3',
  'PDP_Alt4',
  'PDP_Alt5',
  'PDP_Alt6',
  'PDP_Alt7',
  'PDP_Alt8',
  'PDP_Alt9',
  'PDP_Alt10',
  'PDP_Alt11',
  'PDP_Alt12',
  'PDP_Hero',
  'PDP_look1',
  'PDP_look2',
  'PDP_look3',
  'PDP_look4',
  'PDP_look5',
  'VISUALIZER_JACKET_FRONT',
  'VISUALIZER_JACKET_FULL',
  'VISUALIZER_JACKET_COLLECTION_VIEW',
  'VISUALIZER_SHIRT_COLLAR',
  'VISUALIZER_SHIRT_FULL',
  'VISUALIZER_TIE_FULL',
  'VISUALIZER_TIE_NO_STRAP',
  'VISUALIZER_POCKET_SQUARE',
  'VISUALIZER_VEST_FRONT',
  'VISUALIZER_VEST_FULL',
];

interface Props {
  media: Media;
  handleChange: (id: number | string, field: string, value: string) => void;
  handleCancel: (canceled: Media) => void;
}

const MediaFields = ({ media, handleChange, handleCancel }: Props) => (
  <BoxGrid mb={3} key={media.hosted_content_id}>
    <FormInput
      darkMode
      label="Name"
      name="description"
      combineLabel
      value={media.description}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleChange(media.hosted_content_id!, e.target.name, e.target.value)
      }
    />

    <FormSelect
      darkMode
      label="Label"
      name="label"
      combineLabel
      value={media.label}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleChange(media.hosted_content_id!, 'label', e.target.value)
      }
    >
      <option value="">-</option>
      {mediaLabels.map((label, i) => (
        <option value={label} key={i}>
          {label}
        </option>
      ))}
    </FormSelect>

    <Button size="sm" onClick={() => handleCancel(media)} style={{ alignSelf: 'stretch' }}>
      Cancel
    </Button>
  </BoxGrid>
);

export default MediaFields;
