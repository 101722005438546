import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import jump from 'jump.js';
import {
  Container,
  Message,
  Row,
  Header,
  Column,
  Button,
  Spinner,
  FormInput,
  FormTextArea,
  Box,
} from '@generationtux/component-library';

import { Page as PageType } from '../../types';
import { getPageQuery, createPageQuery, updatePageQuery } from '../../services/MediaApi';

import Breadcrumbs from '../Breadcrumbs';
import { isErrorResponseCode } from '../../helpers/Helpers';

interface Props extends RouteComponentProps<{ id?: string }> {
  creating: boolean;
}

interface State {
  loading: boolean;
  pageLoadError: boolean;
  submitError: boolean;
  isSubmitting: boolean;
  submitSuccess: boolean;
  submitErrorMessage: string;
  page: PageType;
}

export default class Page extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      page: {
        heading: '',
        slug: '',
        subHeading: '',
        title: '',
        metaDescription: '',
      },
      pageLoadError: false,
      submitError: false,
      submitErrorMessage: '',
      isSubmitting: false,
      submitSuccess: false,
    };
  }

  async componentDidMount() {
    if (!this.props.creating) {
      await this.getPage();
    }
    return this.setState({ loading: false });
  }

  getPage = async () => {
    const { match } = this.props;

    try {
      const mediaRes = await getPageQuery(Number(match.params.id));

      if (mediaRes.status && mediaRes.status !== 200 && mediaRes.status !== 201)
        throw new Error(`Bad response status (${mediaRes.statusText}) loading page.`);

      const data = await mediaRes.json();

      if (data.data && data.data.page) {
        this.setState({
          page: data.data.page,
        });
      } else {
        this.setState({ pageLoadError: true });
      }
    } catch (e) {
      this.setState({ pageLoadError: true });
      console.error(e);
    }
  };

  getSubmitText() {
    if (this.state.isSubmitting) {
      return this.props.creating ? 'Creating...' : 'Updating...';
    }
    return this.props.creating ? 'Create Page' : 'Update Page';
  }

  buildCreateData() {
    return {
      input: { ...this.state.page, slug: `/${this.state.page.slug}` },
    };
  }

  buildUpdateData() {
    if (!this || !this.props || !this.props.match || !this.props.match.params || !this.props.match.params.id) {
      throw new Error(`Missing Page ID`);
    }
    return {
      input: {
        heading: this.state.page.heading,
        subHeading: this.state.page.subHeading,
        title: this.state.page.title,
        metaDescription: this.state.page.metaDescription,
      },
      id: parseInt(this.props.match.params.id, 10),
    };
  }

  handlePageChange = (field: string, value: string) => {
    let filteredValue = value;
    filteredValue = value.trimStart();
    if (field === 'slug') {
      filteredValue = value.trim().toLowerCase();
      if (filteredValue.substring(0, 1) === '/') filteredValue = filteredValue.substring(1);
    }

    this.setState(state => ({
      page: Object.assign({}, state.page, {
        [field]: filteredValue,
      }),
    }));
  };

  handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState(() => ({
      isSubmitting: true,
      submitError: false,
      submitErrorMessage: '',
    }));

    const [createOrEditPage, buildRequestData, query] = this.props.creating
      ? [createPageQuery, 'buildCreateData' as keyof Page, 'createPage']
      : [updatePageQuery, 'buildUpdateData' as keyof Page, 'updatePage'];

    try {
      const response = await createOrEditPage(this[buildRequestData]());

      if (isErrorResponseCode(response.status)) {
        throw new Error(`Bad response status (${response.status}) creating page`);
      }

      const data = await response.json();

      if (data.errors && data.errors.length > 0) {
        throw new Error(data['errors'][0]['message']);
      }

      if (!data.data || !data.data[query]) {
        throw new Error('Got an invalid response body from the API');
      }

      this.handleSuccess();
    } catch (e) {
      this.handleError([e]);
    }
  };

  handleSuccess = () =>
    this.setState(
      () => ({
        isSubmitting: false,
        submitSuccess: true,
      }),
      () => {
        jump('#page-container');
        if (this.props.creating) {
          setTimeout(() => this.props.history.push('/pages'), 2000);
        } else {
          setTimeout(() => this.setState({ submitSuccess: false }), 2000);
        }
      }
    );

  handleError = (errs: Error[]) => {
    this.setState(
      () => ({
        submitError: true,
        isSubmitting: false,
        submitErrorMessage: errs[0].message,
      }),
      () => {
        jump('#page-container');
      }
    );
  };

  render() {
    const { loading, submitSuccess, pageLoadError, page, isSubmitting, submitError, submitErrorMessage } = this.state;
    const { creating } = this.props;

    if (loading) return <Spinner />;

    return (
      <Container id="page-container">
        <Row mt={2} mb={4}>
          <Column columnSm={12}>
            <Breadcrumbs />
          </Column>
        </Row>

        {pageLoadError ? (
          <Message messageType="error" msg={'There was a problem loading this page. '} />
        ) : (
          <Row justifyContent="space-between" alignItems="flex-start" flexWrap="wrap" flexDirection="row-reverse">
            <Column
              column={12}
              columnSm={4}
              columnMd={5}
              columnLg={4}
              style={{ position: 'sticky', top: 32, zIndex: 1000 }}
            >
              <Box backgroundColor="white" shadowLevel={3} p={[3, 3, 4, 4, 4, 4]} mb={4}>
                <Header type={1} size={3} mb={3} textTransform="none">
                  Page
                </Header>
                <Button
                  style={{ width: '100%' }}
                  buttonType="info"
                  onClick={(e: React.FormEvent<HTMLFormElement>) => this.handleSumbit(e)}
                  disabled={isSubmitting}
                >
                  {this.getSubmitText()}
                </Button>

                {submitSuccess && (
                  <Message messageType="success" msg={`Page ${creating ? 'Created' : 'Updated'} Successfully!`} />
                )}
              </Box>
            </Column>

            <Column column={12} columnSm={8} columnMd={6} columnLg={7}>
              <Row>
                <Column columnSm={12} display="flex" flexDirection="column" alignItems="flex-start">
                  <Header mb={4} type={2}>
                    Page Info
                  </Header>

                  {creating ? (
                    <FormInput
                      mb={4}
                      combineLabel
                      label="URL Slug"
                      name="slug"
                      value={page.slug}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.handlePageChange(e.target.name, e.target.value)
                      }
                    />
                  ) : (
                    <FormInput
                      mb={4}
                      combineLabel
                      label="URL Slug"
                      name="slug"
                      value={page.slug}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.handlePageChange(e.target.name, e.target.value)
                      }
                      readOnly
                      disabled
                    />
                  )}

                  <FormInput
                    mb={4}
                    combineLabel
                    label="Heading"
                    name="heading"
                    value={page.heading ? page.heading : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handlePageChange(e.target.name, e.target.value)
                    }
                  />

                  <FormInput
                    mb={4}
                    combineLabel
                    label="Sub Heading"
                    name="subHeading"
                    value={page.subHeading ? page.subHeading : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handlePageChange(e.target.name, e.target.value)
                    }
                  />

                  <Header mb={4} type={2}>
                    SEO
                  </Header>

                  <FormInput
                    mb={4}
                    combineLabel
                    label="Title"
                    name="title"
                    value={page.title ? page.title : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handlePageChange(e.target.name, e.target.value)
                    }
                  />

                  <FormTextArea
                    mb={4}
                    label="Meta Description"
                    name="metaDescription"
                    value={page.metaDescription ? page.metaDescription : ''}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      this.handlePageChange(e.target.name, e.target.value)
                    }
                  />
                </Column>
              </Row>
            </Column>

            {submitError && (
              <Row>
                <Column columnSm={12} my={3}>
                  <Message messageType="error" msg={submitErrorMessage} />
                </Column>
              </Row>
            )}
          </Row>
        )}
      </Container>
    );
  }
}
