import React from 'react';
import styled from 'styled-components';
import { Button, Box, Header, Text, Line } from '@generationtux/component-library';
import { withRouter, RouteComponentProps } from 'react-router';

import { Permalink } from '../../types';
import { constructPermalinkUrl } from '../../helpers/Helpers';

import PermalinkHighlight from './PermalinkHighlight';
import CopyToClipboard from 'react-copy-to-clipboard';

const permalinkCode = (permalink: string): string =>
  constructPermalinkUrl(permalink)
    .split('/')
    .slice(-1)[0];

interface Props extends RouteComponentProps {
  permalinks: Permalink[];
  searchStr: string;
}

const PermalinksTable = ({ permalinks, history, searchStr }: Props) => (
  <Box>
    {permalinks.map(permalink => (
      <React.Fragment key={permalink.id}>
        <Box mb={3}>
          <Box display="flex">
            <Text textColor="grayDark" mr={1}>
              Permalink:
            </Text>
            <Box mr={2}>
              <PermalinkHighlight str={permalinkCode(permalink.code)} highlight={searchStr} />
            </Box>
          </Box>

          <Box mb={2} display="flex" alignItems="start">
            <Text textColor="grayDark" mr={1}>
              URL:
            </Text>

            <PermalinkHighlight str={permalink.url} highlight={searchStr} />
          </Box>

          <Box display="flex">
            <CopyToClipboard text={constructPermalinkUrl(permalink!.code)!}>
              <Button buttonType="info" size="xs" style={{ cursor: 'pointer' }}>
                Copy Link
              </Button>
            </CopyToClipboard>

            <Button
              buttonType="info"
              size="xs"
              outline
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(`/permalinks/${permalink.id}`)}
              ml={1}
            >
              View/Edit
            </Button>
          </Box>
        </Box>

        <Line my={4} lineColor="grayLighter" />
      </React.Fragment>
    ))}
  </Box>
);

export default withRouter(PermalinksTable);
