import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Plus, Container, Button, Box, Text, Message, Header, Anchor, Spinner } from '@generationtux/component-library';

import { Item } from '../../types';
import { searchProducts } from '../../services/ProductsApi';

import Table from '../styled/Table.style';
import Breadcrumbs from '../Breadcrumbs';
import { FormSearch } from '../FormSearch';

interface State {
  loading: boolean;
  serverError: boolean;
  displayAll: boolean;
  searchQuery: string;
  products: Item[];
}

class Products extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: false,
      serverError: false,
      displayAll: false,
      searchQuery: '',
      products: [],
    };
  }

  handleSearch = async () => {
    this.setState(() => ({ loading: true }));

    try {
      const productsRes = await searchProducts(this.state.searchQuery);

      if (productsRes.status && productsRes.status !== 200 && productsRes.status !== 201)
        throw new Error(`Bad response status (${productsRes.statusText}) loading products.`);

      const data = await productsRes.json();

      this.setState(() => ({
        products: data,
        loading: false,
      }));
    } catch (e) {
      this.setState(() => ({
        serverError: true,
        loading: false,
      }));

      console.error(e);
    }
  };

  handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  changeDisplayAll = () => this.setState(() => ({ displayAll: !this.state.displayAll }));

  render() {
    const { history } = this.props;
    const { searchQuery, displayAll, products, loading, serverError } = this.state;

    return (
      <>
        <Container>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Header type={1}>Products</Header>

              <Breadcrumbs />
            </Box>
            <Button buttonType="primary" mt={3} buttonIcon={<Plus />} href={'/products/create'}>
              New Product
            </Button>
          </Box>
        </Container>

        <FormSearch
          combineLabel
          elementWidth="100%"
          label="Search"
          name="searchQuery"
          value={searchQuery}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => this.handleSearchKeyPress(e)}
          onClick={() => {
            this.handleSearch();
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ searchQuery: e.target.value })}
          placeholder="Search for product..."
        />
        <Container>
          <Box my={3} onClick={this.changeDisplayAll}>
            <input
              type="checkbox"
              name="checkbox"
              checked={displayAll}
              readOnly
              style={{ display: 'inline', marginRight: '8px' }}
            />
            <label htmlFor="opt_in" style={{ display: 'inline', fontWeight: 400 }}>
              <Text small textColor="grayDark" inline>
                Display deleted products
                {products.filter(product => !product.is_active).length > 0 && (
                  <span> ({products.filter(product => !product.is_active).length} inactive)</span>
                )}
              </Text>
            </label>
          </Box>

          {serverError && <Message messageType="error" msg="There was an error loading products." />}

          {loading ? (
            <Spinner type="minimal" />
          ) : (
            <React.Fragment>
              {products.length > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Text>Catalog Number</Text>
                      </th>
                      <th>
                        <Text>Name</Text>
                      </th>
                      <th>
                        <Text>Category</Text>
                      </th>
                      <th>
                        <Text>Color</Text>
                      </th>
                      <th>
                        <Text>Cost</Text>
                      </th>
                      <th>
                        <Text>Active</Text>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {products
                      .filter(product => (displayAll ? product : product.is_active))
                      .map(product => (
                        <tr key={product.id}>
                          <td>
                            <Text>{product.sku}</Text>
                          </td>
                          <td>
                            <Text>{product.display_name}</Text>
                          </td>
                          <td>
                            <Text>{product.category}</Text>
                          </td>
                          <td>
                            <Text>{product.color}</Text>
                          </td>
                          <td>
                            <Text>{product.cost ? `$${product.cost}` : ''}</Text>
                          </td>
                          <td>
                            <Text>{product.is_active ? 'true' : 'false'}</Text>
                          </td>
                          <td>
                            <Anchor style={{ cursor: 'pointer' }} href={`/products/${product.id}`}>
                              Edit
                            </Anchor>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </React.Fragment>
          )}

          <Button size="sm" buttonType="info" mt={3} display="inline-block" href={'/products/create'}>
            Create a Product
          </Button>
        </Container>
      </>
    );
  }
}

export default withRouter(Products);
