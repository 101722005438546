import React from 'react';
import { Container, Row, Column, Header } from '@generationtux/component-library';

import SpinnerContainer from './SpinnerContainer';

const Loading = () => (
  <Container>
    <Row>
      <Column column={12} py={[4, 4, 4, 5, 5, 5]}>
        <div>
          <SpinnerContainer>
            <Header type={2} size={3} textColor="grayDark" center>
              Loading
            </Header>
          </SpinnerContainer>
        </div>
      </Column>
    </Row>
  </Container>
);

export default Loading;
