import { authenticateToken } from './WarehouseApi';

const Auth = {
  loggedIn() {
    return localStorage.getItem('token') !== null;
  },

  getHeaders() {
    return {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  },

  logout() {
    localStorage.removeItem('token');
    window.location.href = '/login';
  },

  validateToken() {
    return authenticateToken();
  },
};

export default Auth;
