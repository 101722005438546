import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Row } from '@generationtux/component-library';

import { Item, Product } from '../../types';

import CollectionProduct from './CollectionProduct';

interface SortableContainerProps {
  products: Product[];
  category: string;
  getProductImage: (item: Item) => string;
}

export default SortableContainer(({ products, category, getProductImage }: SortableContainerProps) => (
  <Row flexWrap="wrap" mx={[1, 1, 1, 1, 1, 1]}>
    {products.map((product, i) => (
      <CollectionProduct product={product} key={product.id} index={i} category={category} image={getProductImage(product)} />
    ))}
  </Row>
));
