import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Box, Column, Header, Text } from '@generationtux/component-library';

import { Product } from '../../types';
import { isSwatch } from '../../helpers/Helpers';

interface SortableElementProps {
  product: Product;
  category: string;
  image: string;
}

export default SortableElement(({ product, image, category }: SortableElementProps) => (
  <React.Fragment>
    {isSwatch(category) ? (
      <Column mr={[3, 3, 3, 4, 4]} mb={3} px={[0, 0, 0, 0, 0, 0]}>
        <Box>
          <img src={`${image}?${'w=150&h=150'}`} alt={product.display_name} />
          <Text textColor="grayDark" size="sm" mt={1}>
            {product.display_name}
          </Text>
        </Box>
      </Column>
    ) : (
      <Column columnXs={6} columnSm={3} mb={4}>
        <Box>
          <img src={`${image}?${'w=249&h=249'}`} alt={product.display_name} />
        </Box>
        <Box>
          <Header type={1} mt={2} size={6}>
            {product.display_name}
          </Header>
          <Header type={2} size={6} textColor="primary">
            ${product.cost}
          </Header>
        </Box>
      </Column>
    )}
  </React.Fragment>
));
