import { Media, JsonResponse } from '../types';

/* eslint-disable no-useless-escape */
export const slugify = (url: string) => {
  // https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return url
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const constructPermalinkUrl = (code: string) => `${process.env.REACT_APP_PERMALINK_URL}/${code}`;

export const calcDisplayIndex = (media: Media[]) => {
  if (media.length > 0) {
    return media![media.length - 1]!.display_index! + 1;
  }
  return 99;
};

export const isSwatch = (category: string) =>
  !!category && (category === 'Swatch (accessory)' || category === 'Swatch (suit/tux)');

export const buildMedia = (media: Media[]) =>
  media.map(m => ({
    created_by: m.created_by,
    description: m.description,
    display_index: m.display_index,
    hosted_content_id: m.hosted_content_id,
    label: m.label,
  }));

export const getMimeType = (string: string) => string.split('.')[string.split('.').length - 1];
export const isVideo = (string: string) => videoMimeTypes.some(mimeType => getMimeType(string).includes(mimeType));

export const videoMimeTypes = ['mp4', 'ogx', 'oga', 'ogv', 'ogg', 'webm']; // types allowed in products
export const dropzoneConfig = {
  postUrl: `${process.env.REACT_APP_PRODUCTS_URL}/v3/media?organization_id=${process.env.REACT_APP_ORGANIZATION_ID}`,
};

export const djsImageConfig = {
  paramName: 'image',
  dictDefaultMessage: 'Drag files here or click to upload.',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    Accept: 'application/json',
  },
};

export const djsVideoConfig = {
  ...djsImageConfig,
  paramName: 'video',
};

export const isErrorResponseCode = (status: number | string) => Number(status) >= 400;

export const getResponseBody = async <T = any>(res: JsonResponse<T>): Promise<string | T> => {
  const contentType = res.headers.get('Content-Type');

  if (contentType && contentType.toLowerCase().includes('application/json')) {
    return await res.clone().json();
  }

  return await res.clone().text();
};

export const fallbackErrorMessage =
  'An unexpected error occurred and no message could be derived from it';

/**
 * Returns an error message from the given value, or falls back to a string that
 * indicates that there was an error, but that no details could be inferred
 * 
 * We attempt to find an error mainly based on the conventions of our APIs - for
 * example, if the given value is an array we'll assume that the response comes
 * from an endpoint that uses Laravel's validator API
 */
export const getErrorMessage = (
  e: unknown,
  fallbackMessage = fallbackErrorMessage,
): string => {
  if (typeof e === 'string') {
    return e;
  }

  if (Array.isArray(e)) {
    const firstErrorMessage = e.find(err => typeof err === 'string');

    if (firstErrorMessage) {
      return firstErrorMessage;
    }
  }

  if (!isRegularObject(e) || !hasErrorMessage(e)) {
    return fallbackMessage;
  }

  return (e.error as string) || (e.message as string);
};

/**
 * Returns a boolean indicating whether the given object literal has one of the
 * properties that we typically use as part of error responses
 */
export const hasErrorMessage = (subject: ObjectLiteral): subject is HasErrorMessage => {
  if (!isRegularObject(subject)) {
    return false;
  }

  return typeof subject.message === 'string' || typeof subject.error === 'string';
};

export type HasErrorMessage =
  | { message: string; error?: string }
  | { error: string; message?: string };

export interface ObjectLiteral {
  [key: string]: any;
}

/**
 * Returns a boolean indicating whether the given `subject` is an object literal,
 * since according to JavaScript, an `object` could be any of the following:
 * 
 * 1. An object
 * 2. An array
 * 3. Null :(
 * 
 * Functions are represented by their own type but are still objects, and can have
 * properties and methods
 */
export const isRegularObject = (subject: unknown): subject is ObjectLiteral => {
  const hasExpectedType = typeof subject === 'object' || typeof subject === 'function';

  if (!hasExpectedType || subject === null) {
    return false;
  }

  return Array.isArray(subject) === false;
};
