import React from 'react';
import { Img } from '@generationtux/component-library';

import { Media } from '../../../types';
import { isVideo, getMimeType } from '../../../helpers/Helpers';

interface Props {
  media: Media;
}

export default ({ media }: Props) => (
  <>
    {isVideo(media.url!) ? (
      <video width="50" height="70" controls>
        <source src={media.url} type={`video/${getMimeType(media.url!)}`} />
        Your browser does not support the video tag.
      </video>
    ) : (
      <Img maxWidth={48} src={media.url} alt={media.description} />
    )}
  </>
);
