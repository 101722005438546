import React, { Component } from 'react';
import ClientOAuth2 from 'client-oauth2';
import { Box, Button, Column, Header, Row, Text, FormInput } from '@generationtux/component-library';

interface Props {}

interface State {
  email: string;
  password: string;
  error: boolean;
  submitting: boolean;
}

class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      submitting: false,
    };
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const oauth = new ClientOAuth2({
      clientId: process.env.REACT_APP_WAREHOUSE_CLIENT_ID,
      clientSecret: process.env.REACT_APP_WAREHOUSE_CLIENT_SECRET,
      accessTokenUri: process.env.REACT_APP_WAREHOUSE_TOKEN_URL,
      authorizationUri: process.env.REACT_APP_WAREHOUSE_OAUTH_URL,
      redirectUri: '/',
      scopes: ['read', 'write'],
    });
    oauth.owner
      .getToken(this.state.email, this.state.password)
      .then(({ accessToken }) => {
        localStorage.setItem('token', accessToken);
        localStorage.setItem('cmsUserEmail', this.state.email);
        // The token is set by the oauth package.
        window.location.href = '/';
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true, submitting: false });
      });
  };

  handleChange = (field: string, value: string) => {
    this.setState(state => ({
      ...state,
      [field]: value,
    }));
  };

  render() {
    const { email, password, submitting, error } = this.state;

    return (
      <Row display="flex" justifyContent="center" alignContent="center" alignItems="center">
        <Column column={12} style={{ maxWidth: 400 }}>
          <Box>
            <Header mb={4}>{process.env.REACT_APP_ORGANIZATION_ID === '1' ? 'Gentux' : 'Menguin'} CMS</Header>

            <form onSubmit={this.handleSubmit}>
              <Box mt={3}>
                <FormInput
                  combineLabel
                  style={{ width: '100%' }}
                  label="Email"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  name="email"
                  id="email"
                  placeholder="Username/Email"
                />
              </Box>
              <Box mt={3}>
                <FormInput
                  combineLabel
                  style={{ width: '100%' }}
                  label="Password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                />
              </Box>

              {error && (
                <Text textColor="danger" mb={2}>
                  Invalid credentials.
                </Text>
              )}

              <Button
                disabled={submitting}
                type="submit"
                id="btn-login"
                buttonType="primary"
                size="lg"
                mt={3}
                display="block"
                style={{ width: '100%' }}
              >
                Log In
              </Button>
            </form>
          </Box>
        </Column>
      </Row>
    );
  }
}

export default Login;
