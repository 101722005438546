import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Box, variables, Theme } from '@generationtux/component-library';
import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

import Auth from '../services/Auth';

import PrivateRoute from './PrivateRoute';
import Navbar from './Navbar';
import Media from './Media';
import Login from './Login';
import Home from './Home';

import ProductForm from './Products/ProductForm';
import Products from './Products/Products';

import Bundles from './Bundles/Bundles';
import BundleForm from './Bundles/BundleForm';

import Pages from './Pages/Pages';
import Page from './Pages/Page';

import Collections from './Collections/Collections';
import CollectionContainer from './Collections/CollectionContainer';

import Permalinks from './Permalinks/Permalinks';
import PermalinkForm from './Permalinks/PermalinkForm';
import Loading from './utils/Loading';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  p, li {
    font-family: ${props => variables(props).fontBody};
    line-height: ${props => variables(props).fontBaseLineHeight};
  }
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
  .lozad-picture-img-block img {
    display: block;
  }
  @font-face {
    font-family: 'DomD Reg';
    font-display: swap;
    src: url('/fonts/DomDWeb-Regular.eot'); /* IE9 Compat Modes */
    src: url('/fonts/DomDWeb-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/DomDWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
    font-style: normal;
  }
`;

class App extends Component {
  state = {
    isLoading: true,
    isAuthenticated: false,
  };

  async componentDidMount() {
    if (window.location.href.includes('/login')) {
      return this.setState({ isLoading: false });
    }

    if (window.analytics && window.analytics.identify && window.localStorage.cmsUserEmail) {
      window.analytics.identify(window.localStorage.cmsUserEmail, {
        email: window.localStorage.cmsUserEmail,
      })
    }

    try {
      const authRes = await Auth.validateToken();

      if (!authRes.status || (authRes.status && authRes.status !== 200 && authRes.status !== 201)) {
        throw new Error('Please login.');
      }

      const data = await authRes.json();

      if (data.data && data.data.errors && data.data.errors.length > 0) throw data.errors[0].message;

      const isNotCMSUser =
        data.data &&
        data.data.userInfo &&
        data.data.userInfo.groups.filter(({ name }: { name: string }) => name === 'cms_user').length === 0;

      if (isNotCMSUser) {
        throw new Error('The user is not a cms user.');
      }

      this.setState({
        isAuthenticated: true,
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
      localStorage.removeItem('token');
      this.setState({
        isAuthenticated: false,
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Theme brand={'gentux'}>
          <GlobalStyle />
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <Router>
              {Auth.loggedIn() && <Navbar />}

              <Box my={5}>
                <Route path="/login" component={Login} />

                <PrivateRoute exact path="/" component={Home} isAuthenticated={this.state.isAuthenticated} />

                <PrivateRoute exact path="/media" component={Media} isAuthenticated={this.state.isAuthenticated} />

                <PrivateRoute
                  exact
                  path="/collections"
                  component={Collections}
                  isAuthenticated={this.state.isAuthenticated}
                />

                <PrivateRoute
                  path="/collections/:category"
                  component={CollectionContainer}
                  isAuthenticated={this.state.isAuthenticated}
                />

                <Switch>
                  <PrivateRoute
                    exact
                    path="/bundles"
                    component={Bundles}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                  <PrivateRoute
                    exact
                    path="/bundles/create"
                    render={({ ...props }) => <BundleForm {...props} creating />}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                  <PrivateRoute
                    exact
                    path="/bundles/:id"
                    component={BundleForm}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                </Switch>

                <Switch>
                  <PrivateRoute
                    exact
                    path="/products"
                    component={Products}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                  <PrivateRoute
                    exact
                    path="/products/create"
                    render={({ ...props }) => <ProductForm {...props} creating />}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                  <PrivateRoute
                    path="/products/:id"
                    component={ProductForm}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                </Switch>

                <Switch>
                  <PrivateRoute
                    exact
                    path="/permalinks"
                    component={Permalinks}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                  <PrivateRoute
                    path="/permalinks/create"
                    render={({ ...props }) => <PermalinkForm {...props} creating />}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                  <PrivateRoute
                    path="/permalinks/:id"
                    component={PermalinkForm}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                </Switch>

                <Switch>
                  <PrivateRoute exact path="/pages" component={Pages} isAuthenticated={this.state.isAuthenticated} />
                  <PrivateRoute
                    exact
                    path="/pages/create"
                    render={({ ...props }) => <Page {...props} creating />}
                    isAuthenticated={this.state.isAuthenticated}
                  />
                  <PrivateRoute path="/pages/:id" component={Page} isAuthenticated={this.state.isAuthenticated} />
                </Switch>
              </Box>
            </Router>
          )}
        </Theme>
      </div>
    );
  }
}

export default App;
