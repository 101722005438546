import { Bundle } from '../types';
import { fetchMiddleware } from './fetchMiddleware';

export const getBundles = async () =>
  await fetchMiddleware<Bundle[]>(`${process.env.REACT_APP_PRODUCTS_URL}/v2/bundles`);

export const getBundle = async (id: number | string) =>
  await fetchMiddleware<Bundle>(
    `${process.env.REACT_APP_PRODUCTS_URL}/v2/bundles/${id}?with=blockoutDates,products,preconfigured,media,recommendations,swatch,retailBundle`
  );

export const createBundle = async (bundle: Bundle) =>
  await fetchMiddleware<Bundle>(`${process.env.REACT_APP_PRODUCTS_URL}/v3/bundles`, {
    method: 'POST',
    body: JSON.stringify(bundle),
  });

export const updateBundle = async (bundle: Bundle) =>
  await fetchMiddleware<Bundle>(`${process.env.REACT_APP_PRODUCTS_URL}/v2/bundles/${bundle.id}`, {
    method: 'PUT',
    body: JSON.stringify(bundle),
  });
