import React, { ChangeEvent } from 'react';
import {
  Header,
  Trashcan,
  Box,
  Button,
  FormInput,
  Message,
  Plus,
  FormTextArea,
  FormSelect,
  FormRadio,
  FormRadioItem,
  Line,
} from '@generationtux/component-library';

import { slugify } from '../../helpers/Helpers';
import BlockoutDateRangePicker from '../shared/BlockoutDateRangePicker';
import { BlockoutDate, BundleDetail, ProductDetail, ProductFormState } from '../../types';

const VEST_SUB_CATEGORIES = [
  { field: 'suit', value: 'Suit' },
  { field: 'tux', value: 'Tux' },
  { field: 'not_tailored', value: 'Not Tailored' },
];

const TIE_SUB_CATEGORIES = [
  { field: 'Bow Tie', value: 'Bow Tie' },
  { field: 'Windsor Tie', value: 'Windsor Tie' },
];

const TIE_SUBTYPES = [
  'Clip On',
  'Pre Tied',
  'Self Tied',
];

interface Props extends ProductFormState {
  createOrUpdate: () => void;
  activateProduct: (isActive: boolean) => void;
  handleChange: (field: string, value: string | boolean) => void;
  handleUpdateDetail: (index: number, value: string) => void;
  removeDetail: (index: number) => void;
  addDetailInput: () => void;
  addBlockoutDate: (blockoutDate: BlockoutDate) => void;
  removeBlockoutDate: (index: number) => void;
  creating: boolean;
}

const ProductInfo = ({
  product,
  handleChange,
  handleUpdateDetail,
  removeDetail,
  addDetailInput,
  addBlockoutDate,
  removeBlockoutDate,
  createOrUpdate,
  updateProductError,
  submitting,
  updateProductSuccess,
  creating,
  skuErr,
  urlSlugErr,
  displayNameErr,
  costErr,
  patternErr,
  categoryErr,
  typeErr,
  colorErr,
  detailsErr,
  subCategoryAttributeError,
  subtypeAttributeError,
  activateProduct,
}: Props) => {
  const makeDetailInput = (detail: ProductDetail | BundleDetail, i: number) => (
    <Box key={i} mb={'3px'} display="flex">
      <FormInput
        name={detail.detail ? detail.detail : ''}
        value={detail.detail ? detail.detail : ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateDetail(i, e.target.value)}
        style={{ flexGrow: 1 }}
      />

      <Button
        size="sm"
        buttonIcon={<Trashcan iconColor="white" />}
        buttonType="danger"
        onClick={() => removeDetail(i)}
      />
    </Box>
  );

  const buildDetails = () => product!.details!.map((detail, i) => makeDetailInput(detail, i));

  const EMPTY_TEXT_VALUE = '';

  const generateSubCategories = (category: string) => {
    switch (category) {
      case 'Vest':
        return VEST_SUB_CATEGORIES;
      case 'Tie':
        return TIE_SUB_CATEGORIES;
      default:
        return [];
    }
  };

  const errorMsgText = () => 'This field is required.';
  const errorMsg = () => <Message messageType="error" msg={errorMsgText()} mt={2} />;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <FormInput
            mb={4}
            combineLabel
            label="Display Name"
            name="display_name"
            value={product.display_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
            errorMessage={displayNameErr ? errorMsgText() : ''}
          />

          <FormInput
            mb={4}
            combineLabel
            label="Catalog Number"
            name="sku"
            value={product.sku}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
            errorMessage={skuErr ? errorMsgText() : ''}
          />

          <FormInput
            mb={4}
            label="Cost"
            combineLabel
            name="cost"
            value={product.cost}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
            errorMessage={costErr ? errorMsgText() : ''}
          />

          <FormInput
            mb={4}
            combineLabel
            label="Url Slug"
            name="url_slug"
            value={creating ? product.url_slug : `/${product.url_slug}`}
            disabled={!creating}
            disabledMessage="URLs are not editable. Contact engineering to change."
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
            onBlur={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, slugify(e.target.value.trim()))
            }
            errorMessage={urlSlugErr ? errorMsgText() : ''}
          />

          <FormTextArea
            mb={4}
            label="Rental Terms"
            combineLabel
            name="rental_terms"
            value={product.rental_terms || EMPTY_TEXT_VALUE}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e.target.name, e.target.value)}
          />

          <FormTextArea
            mb={4}
            label="Stylist Notes"
            combineLabel
            name="short_description"
            value={product.short_description || EMPTY_TEXT_VALUE}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e.target.name, e.target.value)}
          />

          <Box mb={4} style={{ flexGrow: 1 }}>
            <Box backgroundColor="grayLighter" p={3} mb={'3px'}>
              <Header type={2} size={4} style={{ textTransform: 'none', letterSpacing: 0 }}>
                Details
              </Header>
            </Box>
            {product.details && buildDetails()}
            {detailsErr && errorMsg()}

            <Button size="sm" buttonIcon={<Plus color="black" />} buttonType="info" onClick={() => addDetailInput()}>
              Add Detail
            </Button>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column">
          <FormRadio mb={4} label="Displayable on website?">
            <FormRadioItem
              value="true"
              name="displayable"
              label="Yes"
              checked={product.displayable}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, true)}
            />
            <FormRadioItem
              value="false"
              name="displayable"
              label="No"
              checked={!product.displayable}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, false)}
            />
          </FormRadio>

          <FormRadio mb={4} label="Type" errorMessage={typeErr ? errorMsgText() : ''}>
            <FormRadioItem
              value="Rental"
              label="Rental"
              name="type"
              checked={product.type === 'Rental'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
            />
            <FormRadioItem
              value="Purchase"
              label="Purchase"
              name="type"
              checked={product.type === 'Purchase'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
            />
          </FormRadio>

          <FormSelect
            mb={4}
            label="Category"
            combineLabel
            value={product.category}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e.target.name, e.target.value)}
            name="category"
            errorMessage={categoryErr ? errorMsgText() : ''}
          >
            <option />
            <option value="Tie">Tie</option>
            <option value="Cummerbund">Cummerbund</option>
            <option value="Promo">Promo</option>
            <option value="Cufflinks">Cufflinks</option>
            <option value="T-Shirt">T-Shirt</option>
            <option value="Tie Bar">Tie Bar</option>
            <option value="Shirt">Shirt</option>
            <option value="Vest (backless)">Vest (backless)</option>
            <option value="Vest">Vest</option>
            <option value="Jacket">Jacket</option>
            <option value="Shoe">Shoe</option>
            <option value="Pant">Pant</option>
            <option value="Jewelry">Jewelry</option>
            <option value="Lapel Pin">Lapel Pin</option>
            <option value="Socks">Socks</option>
            <option value="Belt">Belt</option>
            <option value="Headwear">Headwear</option>
            <option value="Pocket Square">Pocket Square</option>
            <option value="Suspenders">Suspenders</option>
            <option value="Swatch (accessory)">Swatch (accessory)</option>
            <option value="Swatch (suit/tux)">Swatch (suit/tux)</option>
          </FormSelect>

          {(product.category === 'Tie' || product.category === 'Vest') && (
            <>
              <FormRadio
                mb={4}
                label="Sub-Category"
                errorMessage={
                  subCategoryAttributeError ? `This field is required when creating a ${product.category}` : ''
                }
              >
                {generateSubCategories(product.category).map(cat => (
                  <FormRadioItem
                    key={cat.field}
                    value={cat.field}
                    label={cat.value}
                    checked={product.sub_category === cat.field}
                    name="sub_category"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
                  />
                ))}
              </FormRadio>
            </>
          )}

          {product.category === 'Tie' && (
            <>
              <FormRadio
                mb={4}
                label="Tie Type"
                errorMessage={
                  subtypeAttributeError ? 'This field is required when creating a tie' : ''
                }
              >
                {TIE_SUBTYPES.map((tieType, _) => (
                  <FormRadioItem
                    key={tieType}
                    value={tieType}
                    label={tieType}
                    checked={product.subtype === tieType}
                    name="subtype"
                    onChange={(_: ChangeEvent<HTMLInputElement>) => handleChange('subtype', tieType)}
                  />
                ))}
              </FormRadio>
            </>
          )}

          <FormSelect
            mb={4}
            label="Pattern"
            combineLabel
            value={product.pattern ? product.pattern : ''}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e.target.name, e.target.value)}
            name="pattern"
            errorMessage={patternErr ? errorMsgText() : ''}
          >
            <option />
            <option value="Argyle">Argyle</option>
            <option value="Brocade">Brocade</option>
            <option value="Camo">Camo</option>
            <option value="Chevron">Chevron</option>
            <option value="Dobby">Dobby</option>
            <option value="Dots">Dots</option>
            <option value="Floral">Floral</option>
            <option value="Gingham">Gingham</option>
            <option value="Graphic">Graphic</option>
            <option value="Micro Square">Micro Square</option>
            <option value="Microcheck">Microcheck</option>
            <option value="Paisley">Paisley</option>
            <option value="Plaid">Plaid</option>
            <option value="Solid">Solid</option>
            <option value="Striped">Striped</option>
            <option value="Zig Zag">Zig Zag</option>
          </FormSelect>

          <FormSelect
            mb={4}
            label="Color"
            combineLabel
            value={product.color}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e.target.name, e.target.value)}
            name="color"
            errorMessage={colorErr ? errorMsgText() : ''}
          >
            <option />
            <option value="Beige">Beige</option>
            <option value="Black">Black</option>
            <option value="Blue">Blue</option>
            <option value="Brown">Brown</option>
            <option value="Burgundy">Burgundy</option>
            <option value="Green">Green</option>
            <option value="Grey">Grey</option>
            <option value="Orange">Orange</option>
            <option value="Pink">Pink</option>
            <option value="Purple">Purple</option>
            <option value="Red">Red</option>
            <option value="Tan">Tan</option>
            <option value="White">White</option>
            <option value="Yellow">Yellow</option>
            <option value="Miscellaneous">Miscellaneous</option>
          </FormSelect>
        </Box>

        <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />

        <Header type={2} mb={3}>
          Blockout Dates
        </Header>
        <Box mb={4} style={{ flexGrow: 1 }}>
          <BlockoutDateRangePicker
            addBlockoutDate={addBlockoutDate}
            removeBlockoutDate={removeBlockoutDate}
            blockoutDates={product.blockout_dates}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProductInfo;
