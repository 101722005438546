import { fetchMiddleware } from './fetchMiddleware';

/**
 * Passes along a query or mutation to media.
 * @param  {String} query
 * @param  {Object} variables
 */
export const graphQL = async (query: string, variables = {}) =>
  await fetchMiddleware(`${process.env.REACT_APP_WAREHOUSE_URL}/graphql_token`, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables,
    }),
  });

/**
 * Authenticate a warehouse token
 */
export const authenticateToken = async () =>
  await graphQL(`
    {
      userInfo {
        id
        username
        type
        groups {
          name
        }
        permissions {
          name
          codename
        }
      }
    }`);
