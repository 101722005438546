import Moment from 'moment';
import React, { Component } from 'react';
import {
  Trashcan,
  Calendar,
  Header,
  Text,
  Box,
  Plus,
  Button,
  variables,
  space,
  FormRadio,
  FormRadioItem,
} from '@generationtux/component-library';
import styled from 'styled-components';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { BlockoutDate } from '../../types';

const StyledTable = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  row-gap: 1px;
  & > div {
    background: ${props => variables(props).grayLighter};
    padding: ${space[3]}px 0 ${space[3]}px ${space[3]}px;
    &:nth-child(4n) {
      padding: 0 0 0 ${space[3]}px;
    }
  }
  td,
  th {
    padding: 16px;
  }
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  width: 100%;
  .react-daterange-picker__wrapper {
    background: ${props => variables(props).white};
    border-color: ${props => variables(props).gray};
  }
  .react-daterange-picker__inputGroup {
    text-align: center;
  }
  .react-daterange-picker__inputGroup__input {
    padding: ${space[2]}px 0;
    box-sizing: border-box;
  }
  .react-daterange-picker__button:enabled {
    padding: ${space[3]}px;
  }
`;

interface Props {
  blockoutDates?: BlockoutDate[];
  removeBlockoutDate: (index: number) => void;
  addBlockoutDate: (blockoutDate: BlockoutDate) => void;
}

interface State {
  blockoutDate: BlockoutDate;
  value: Date[];
}

export default class BlockoutDateRangePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      blockoutDate: {
        availability: 'general',
        start_date: '',
        end_date: '',
      },
      value: [],
    };
  }

  handleDateChange = (startDate: Date, endDate: Date) => {
    this.setState(state => ({
      ...state,
      blockoutDate: {
        ...state.blockoutDate,
        start_date: Moment(startDate).format('l'),
        end_date: Moment(endDate).format('l'),
      },
      value: [startDate, endDate],
    }));
  };

  renderBlockoutDates = (dates: BlockoutDate[]) =>
    dates &&
    dates.map((date, i) => {
      return (
        <React.Fragment key={i}>
          <div>
            <Text>{date.start_date}</Text>
          </div>
          <div>
            <Text>{date.end_date}</Text>
          </div>
          <div>
            <Text>{date.availability!.replace('-', ' ')} </Text>
          </div>
          <Box display="flex" justifyContent="flex-end" style={{ display: 'flex', alignItems: 'stretch' }}>
            <Button
              buttonType="danger"
              size="sm"
              buttonIcon={<Trashcan iconColor="white" />}
              onClick={() => {
                this.props.removeBlockoutDate(i);
              }}
            ></Button>
          </Box>
        </React.Fragment>
      );
    });

  render() {
    const { blockoutDates, addBlockoutDate } = this.props;
    const { blockoutDate, value } = this.state;
    const dateRangeValue = value && value[0] ? value : null;

    return (
      <React.Fragment>
        <StyledTable>
          {blockoutDates && blockoutDates.length > 0 && (
            <>
              <div>
                <Header type={3} size={5} style={{ textTransform: 'none', letterSpacing: 0 }}>
                  Start Date
                </Header>
              </div>
              <div>
                <Header type={3} size={5} style={{ textTransform: 'none', letterSpacing: 0 }}>
                  End Date
                </Header>
              </div>
              <div>
                <Header type={3} size={5} style={{ textTransform: 'none', letterSpacing: 0 }}>
                  Type
                </Header>
              </div>
              <div></div>
            </>
          )}

          {this.renderBlockoutDates(blockoutDates!)}
        </StyledTable>

        <Box display="flex" flexDirection="column">
          <Box backgroundColor="grayLighter" p={3}>
            <Header type={2} size={4} style={{ textTransform: 'none', letterSpacing: 0 }}>
              Blockout Date Range
            </Header>
          </Box>
          <Box mb={2}>
            <Text as="div">
              <StyledDateRangePicker
                clearIcon={null}
                calendarIcon={<Calendar width={'15'} height={'15'} />}
                value={dateRangeValue}
                onChange={(value: Date[]) =>
                  value
                    ? this.handleDateChange(value[0], value[1])
                    : this.setState({
                        blockoutDate: {
                          ...this.state.blockoutDate,
                          start_date: '',
                          end_date: '',
                        },
                      })
                }
              />
            </Text>
          </Box>

          <FormRadio mb={2} label="Blockout Type">
            <FormRadioItem
              name="blockout-type"
              value="general"
              label="general"
              checked={this.state.blockoutDate.availability === 'general'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const availability = e.target.value;
                this.setState(state => ({
                  ...state,
                  blockoutDate: { ...state.blockoutDate, availability },
                }));
              }}
            />
            <FormRadioItem
              name="blockout-type"
              value="hto"
              label="hto"
              checked={this.state.blockoutDate.availability === 'hto'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const availability = e.target.value;
                this.setState(state => ({
                  ...state,
                  blockoutDate: { ...state.blockoutDate, availability },
                }));
              }}
            />
            <FormRadioItem
              name="blockout-type"
              value="coming-soon"
              label="coming soon"
              checked={this.state.blockoutDate.availability === 'coming-soon'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const availability = e.target.value;
                this.setState(state => ({
                  ...state,
                  blockoutDate: { ...state.blockoutDate, availability },
                }));
              }}
            />
          </FormRadio>

          <Button
            buttonType="info"
            size="sm"
            buttonIcon={<Plus color="black" />}
            onClick={() => addBlockoutDate(blockoutDate)}
            disabled={
              this.state.blockoutDate.start_date === '' ||
              this.state.blockoutDate.end_date === '' ||
              this.state.blockoutDate.availability === ''
            }
          >
            Add Blockout Date
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}
