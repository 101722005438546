import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Plus,
  Container,
  Row,
  Column,
  Message,
  Text,
  Box,
  Button,
  Header,
  Anchor,
  Spinner,
} from '@generationtux/component-library';

import { Page } from '../../types';
import { getPagesQuery } from '../../services/MediaApi';

import Table from '../styled/Table.style';
import Breadcrumbs from '../Breadcrumbs';
import { FormSearch } from '../FormSearch';

interface State {
  loading: boolean;
  loadError: boolean;
  numberDisplayed: number;
  searchQuery: string;
  pages: Page[];
  filteredPages: Page[];
}

class Pages extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: true,
      pages: [],
      loadError: false,
      numberDisplayed: 15,
      searchQuery: '',
      filteredPages: [],
    };
  }

  async componentDidMount() {
    try {
      const mediaRes = await getPagesQuery();

      if (mediaRes.status && mediaRes.status !== 200 && mediaRes.status !== 201)
        throw new Error(`Bad response status (${mediaRes.statusText}) loading pages.`);

      const data = await mediaRes.json();

      if (data.data && data.data.pages) {
        this.setState({
          pages: data.data.pages,
          filteredPages: data.data.pages,
        });
      } else {
        this.setState({
          loadError: true,
        });
      }
    } catch (e) {
      this.setState({
        loadError: true,
      });
      console.log(e);
    }

    this.setState({ loading: false });
  }

  getNumberDisplayed() {
    return this.state.numberDisplayed > this.state.filteredPages.length
      ? this.state.filteredPages.length
      : this.state.numberDisplayed;
  }

  getFilterResults() {
    return (
      <Row>
        <Column columnSm={12}>
          <Text small>
            Showing {this.getNumberDisplayed()} of {this.state.filteredPages.length}
          </Text>
        </Column>
      </Row>
    );
  }

  seeMore() {
    this.setState(state => ({ numberDisplayed: state.numberDisplayed + 10 }));
  }

  handleSearchChange = (value: string) => {
    const filteredPages = this.state.pages.filter((page: Page) => page.slug!.toLocaleLowerCase().includes(value));
    this.setState({
      filteredPages,
      searchQuery: value,
    });
  };

  render() {
    const { history } = this.props;
    const { loading, loadError, searchQuery, filteredPages, numberDisplayed, pages } = this.state;

    if (loading) return <Spinner />;

    return (
      <>
        <Container>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Header type={1}>Pages</Header>

              <Breadcrumbs />
            </Box>
            <Button buttonType="primary" mt={3} buttonIcon={<Plus />} href={'/pages/create'}>
              New Page
            </Button>
          </Box>
        </Container>

        {loadError ? (
          <Container>
            <Message messageType="error" msg="There was an error loading pages" />
          </Container>
        ) : (
          <>
            <FormSearch
              label="Search"
              combineLabel
              style={{ width: '100%' }}
              name="searchQuery"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleSearchChange(e.target.value)}
              placeholder="Search By Slug"
            />

            <Container>
              <Box my={3}>{this.getFilterResults()}</Box>

              <Table>
                <thead>
                  <tr>
                    <th>
                      <Text>URL Slug</Text>
                    </th>
                    <th>
                      <Text>Heading</Text>
                    </th>
                    <th>
                      <Text>Sub Heading</Text>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {filteredPages.length > 0 &&
                    filteredPages.slice(0, numberDisplayed - 1).map(page => (
                      <tr key={page.id}>
                        <td>
                          <Text>{page.slug}</Text>
                        </td>
                        <td>
                          <Text>{page.heading}</Text>
                        </td>
                        <td>
                          <Text>{page.subHeading}</Text>
                        </td>
                        <td>
                          <Anchor style={{ cursor: 'pointer' }} href={`/pages/${page.id}`}>
                            Edit
                          </Anchor>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Box my={4}>
                <Button
                  type="submit"
                  block
                  buttonType="primary"
                  onClick={() => this.seeMore()}
                  disabled={numberDisplayed >= pages.length}
                >
                  Load More
                </Button>
              </Box>

              <Box my={3}>{this.getFilterResults()}</Box>

              <Button buttonType="info" mt={3} display="inline-block" href={'/pages/create'}>
                Create a Page
              </Button>
            </Container>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Pages);
