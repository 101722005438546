import React from 'react';
import {
  Box,
  Header,
  Button,
  Trashcan,
  Plus,
  FormRadio,
  FormRadioItem,
  FormInput,
  FormTextArea,
} from '@generationtux/component-library';

import { Bundle } from '../../types';
import { slugify } from '../../helpers/Helpers';
interface Props {
  creating: boolean;
  bundle: Bundle;
  updateDetail: (index: number, value: string) => void;
  removeDetail: (index: number) => void;
  addDetailInput: () => void;
  handleChange: (field: string, value: string | boolean) => void;
}

const colorLabels = [
  "Black",
  "Blue",
  "Brown",
  "Burgundy",
  "Green",
  "Grey",
  "Purple",
  "Tan",
  "White",
];

const BundleInfo = ({ creating, bundle, handleChange, updateDetail, removeDetail, addDetailInput }: Props) => (
  <>
    <Box display="flex" flexDirection="column" mb={4}>
      <FormInput
        mb={4}
        combineLabel
        label="Display Name"
        id="displayName"
        name="display_name"
        value={bundle.display_name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
      />

      <FormInput
        mb={4}
        label="Cost"
        combineLabel
        id="price"
        name="cost"
        value={bundle.cost}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
      />

      <FormInput
        mb={4}
        combineLabel
        label="URL Slug"
        name="url_slug"
        value={creating ? bundle.url_slug : `/${bundle.url_slug}`}
        disabled={!creating}
        disabledMessage="URLs are not editable. Contact engineering to change."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleChange(e.target.name, slugify(e.target.value.trim()))}
      />

      <FormTextArea
        mb={4}
        label="Rental Terms"
        combineLabel
        name="rental_terms"
        value={bundle.rental_terms}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e.target.name, e.target.value)}
      />

      <FormTextArea
        mb={4}
        label="Stylist Notes"
        combineLabel
        name="short_description"
        value={bundle.short_description}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e.target.name, e.target.value)}
      />

      <Box>
        <Box backgroundColor="grayLighter" p={3} mb={'3px'}>
          <Header type={2} size={4} style={{ textTransform: 'none', letterSpacing: 0 }}>
            Details
          </Header>
        </Box>

        {bundle.details &&
          bundle.details.map((detail, i) => (
            <Box key={i} mb={'3px'} display="flex">
              <FormInput
                name={`${detail.id}-detail`}
                value={detail.detail ? detail.detail : ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateDetail(i, e.target.value)}
                style={{ flexGrow: 1 }}
              />

              <Button
                size="sm"
                buttonIcon={<Trashcan iconColor="white" />}
                buttonType="danger"
                onClick={() => removeDetail(i)}
              />
            </Box>
          ))}

        <Button size="sm" buttonIcon={<Plus color="black" />} buttonType="info" onClick={() => addDetailInput()}>
          Add Detail
        </Button>
      </Box>
    </Box>

    <Box display="flex" flexDirection="column">
      <FormRadio mb={4} label="Is this a recommended bundle?">
        <FormRadioItem
          value="look"
          name="category"
          label="Yes"
          checked={bundle.category === 'look'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
        />
        <FormRadioItem
          value="preconfigured"
          name="category"
          label="No"
          checked={bundle.category === 'preconfigured'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
        />
      </FormRadio>

      <FormRadio mb={4} label="Tuxedo or Suit?">
        <FormRadioItem
          value="tuxedo"
          name="type"
          label="Tuxedo"
          checked={bundle.type === 'tuxedo'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
        />
        <FormRadioItem
          value="suit"
          name="type"
          label="Suit"
          checked={bundle.type === 'suit'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
        />
      </FormRadio>

      <FormRadio mb={4} label="Rental or Retail">
        <FormRadioItem
          value="false"
          name="is_retail"
          label="Rental"
          checked={!bundle.is_retail}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, false)}
        />
        <FormRadioItem
          value="true"
          name="is_retail"
          label="Retail"
          checked={!!bundle.is_retail}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, true)}
        />
      </FormRadio>

      <FormRadio mb={4} label="Displayable on Website?">
        <FormRadioItem
          value="true"
          name="displayable"
          label="Yes"
          checked={!!bundle.displayable}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, true)}
        />
        <FormRadioItem
          value="false"
          name="displayable"
          label="No"
          checked={!bundle.displayable}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, false)}
        />
      </FormRadio>

      <FormRadio mb={4} label="Available For Rent/Purchase?">
        <FormRadioItem
          value="true"
          name="is_active"
          label="Yes"
          checked={!!bundle.is_active}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, true)}
        />
        <FormRadioItem
          value="false"
          name="is_active"
          label="No"
          checked={!bundle.is_active}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, false)}
        />
      </FormRadio>

      <FormRadio mb={4} label="Available In Boys?">
        <FormRadioItem
          value="true"
          name="available_in_boys"
          label="Yes"
          checked={!!bundle.available_in_boys}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, true)}
        />
        <FormRadioItem
          value="false"
          name="available_in_boys"
          label="No"
          checked={!bundle.available_in_boys}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, false)}
        />
      </FormRadio>

      <FormRadio mb={4} label="Available In Slim?">
        <FormRadioItem
          value="true"
          name="available_in_slim"
          label="Yes"
          checked={!!bundle.available_in_slim}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, true)}
        />
        <FormRadioItem
          value="false"
          name="available_in_slim"
          label="No"
          checked={!bundle.available_in_slim}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, false)}
        />
      </FormRadio>

      <FormRadio mb={4} label="Color">
        {
          colorLabels.map( (label) => 
            <FormRadioItem
              name="color"
              value={label}
              label={label}
              checked={bundle.color === label}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
            />
          )
        }
      </FormRadio>
    </Box>
  </>
);

export default BundleInfo;
