import React from 'react';
import { RouteProps, RouteComponentProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

interface Props extends RouteProps {
  render?: (props: RouteComponentProps) => React.ReactNode;
  isAuthenticated: boolean;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, isAuthenticated, render, ...rest }) => {
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!Component) {
    if (render) {
      return <Route {...rest} render={props => render(props)} />;
    } else {
      return null;
    }
  }
  return <Route {...rest} render={routeProps => <Component {...routeProps} />} />;
};

export default PrivateRoute;
