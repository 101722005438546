import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import jump from 'jump.js';
import { Box, Button, Column, Container, Header, Message, Row, Spinner } from '@generationtux/component-library';

import { Item } from '../../types';
import {
  getBundles,
  getStyledLooks,
  getProductList,
  updateBundleDisplayIndices,
  updateProductsDisplayIndices,
} from '../../services/ProductsApi';

import Breadcrumbs from '../Breadcrumbs';
import CollectionProductList from './CollectionProductList';

interface Props extends RouteComponentProps<{ category?: string }> {}

interface State {
  loading: boolean;
  saving: boolean;
  changesMade: boolean;
  saveSuccess: boolean;
  error: string;
  products: Item[];
}

interface Indexes {
  oldIndex: number;
  newIndex: number;
}

class CollectionContainer extends Component<Props, State> {
  state = {
    loading: true,
    products: [],
    saving: false,
    changesMade: false,
    error: '',
    saveSuccess: false,
  };

  componentDidMount() {
    this.getProducts();
  }

  getProducts = async () => {
    let itemsRequest = null;
    const { category } = this.props.match.params;

    switch (category) {
      case 'tuxedos-and-suits':
        itemsRequest = getBundles();
        break;
      case 'styled-looks':
        itemsRequest = getStyledLooks();
        break;
      case 'vests-and-cummerbunds':
        itemsRequest = getProductList('Vest,Cummerbund');
        break;
      case 'shirts':
        itemsRequest = getProductList('Shirt');
        break;
      case 'ties':
        itemsRequest = getProductList('Tie');
        break;
      case 'accessories':
        itemsRequest = getProductList('Suspenders,Belt,Lapel Pin,Tie Bar,Cufflinks,Pocket Square');
        break;
      case 'accessory-swatches':
        itemsRequest = getProductList('Swatch (accessory)');
        break;
      case 'tux-suit-swatches':
        itemsRequest = getProductList('Swatch (suit/tux)');
        break;
      case 'shoes-and-socks':
        itemsRequest = getProductList('Shoe,Socks');
        break;
      default:
        this.setState(() => ({
          loading: false,
          error: `Error collection category not found: ${category}`,
        }));
        return;
    }

    try {
      const response = await itemsRequest;

      if (response.status && response.status !== 200 && response.status !== 201)
        throw new Error(`Bad response status (${response.statusText}) loading the collection.`);

      const data: Item[] = await response.json();
      const products = data.sort((a, b) => Number(a.display_index) - Number(b.display_index));

      this.setState(() => ({ products, loading: false }));
    } catch (err) {
      this.setState(() => ({
        loading: false,
        error: err.response ? err.response.data : err.message,
      }));
      console.error(err);
    }
  };

  getProductImage = (product: Item) => {
    let image = null;
    if (product.media && product.media.length > 0) {
      if (this.props.match.params.category === 'tuxedos-and-suits') {
        // bundle images
        image = product.media.find(
          img => img.description && img.description.toLowerCase().includes('jacket front look')
        );
        if (typeof image === 'undefined') {
          image = product.media.find(img => img.description && img.description.toLowerCase().includes('lapel'));
        }
      } else {
        // product images
        image = product.media.find(img => img.description && img.label && img.label.includes('PDP_Hero'));
      }
      if (!image) {
        image = product.media[0];
      }

      image = image.url!;
    } else {
      // missing image
      image = 'https://media.gentux.com/uUVtG8eenezNdwQtbfBrxkxpvaMp';
    }

    return image;
  };

  handleProductOrder = ({ oldIndex, newIndex }: SortEnd) => {
    // reset array and display indexes
    const resetProducts = arrayMove(this.state.products, oldIndex, newIndex);

    return this.setState(() => ({
      products: resetProducts.map((product: Item, i: number) => ({
        ...product,
        display_index: i,
      })),
      changesMade: true,
    }));
  };

  handleSubmit = async () => {
    this.setState(() => ({ saving: true, saveSuccess: false }));
    const { products } = this.state;
    let request = null;

    if (
      this.props.match.params.category === 'tuxedos-and-suits' ||
      this.props.match.params.category === 'styled-looks'
    ) {
      request = updateBundleDisplayIndices(products);
    } else {
      request = updateProductsDisplayIndices(products);
    }

    try {
      const productsRes = await request;

      if (productsRes.status && productsRes.status !== 200 && productsRes.status !== 201)
        throw new Error(`Bad response status (${productsRes.statusText}) saving the collection.`);

      await productsRes.json();

      this.setState(() => ({
        changesMade: false,
        saving: false,
        saveSuccess: true,
      }));
    } catch (e) {
      this.setState(() => ({
        saving: false,
        loading: false,
        error: e.response ? e.response.data : e.message,
      }));
      console.error(e);
    }

    jump('#content');
  };

  render() {
    const { loading, products, saving, changesMade, saveSuccess, error } = this.state;
    const { category } = this.props.match.params;

    if (loading) return <Spinner />;

    return (
      <Container id="content">
        <Row mt={3}>
          <Column columnSm={12}>
            <Header type={1}>{category!.replace(/-/g, ' ').toUpperCase()}</Header>
          </Column>
        </Row>

        <Row mt={2}>
          <Column columnSm={12} mb={4}>
            <Breadcrumbs />
          </Column>
        </Row>

        {saveSuccess && (
          <Row>
            <Column columnSm={12}>
              <Box>
                <Message messageType="success" msg={`Success! Allow time for your changes to populate to the site.`} />
              </Box>
            </Column>
          </Row>
        )}

        {error && (
          <Row>
            <Column columnSm={12}>
              <Box>
                <Message messageType="error" msg={`Error: ${error}`} />
              </Box>
            </Column>
          </Row>
        )}

        <Row>
          <Column columnSm={12}>
            {!loading && (
              <CollectionProductList
                products={products}
                category={category!}
                getProductImage={this.getProductImage}
                onSortEnd={this.handleProductOrder}
                axis="xy"
              />
            )}
          </Column>
        </Row>

        <Row mb={4}>
          <Column columnSm={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                outline
                buttonType="default"
                size="lg"
                onClick={() => this.handleSubmit()}
                disabled={loading || saving || !changesMade}
              >
                {!saving ? 'Save' : 'Saving'}
              </Button>
            </Box>
          </Column>
        </Row>
      </Container>
    );
  }
}

export default CollectionContainer;
