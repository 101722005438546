import React, { Component } from 'react';
import { DropzoneComponent, ErrorCallback } from 'react-dropzone-component';
import { Column, Container, Header, Row } from '@generationtux/component-library';

import { Media as MediaType } from '../types';

import MediaTable from './shared/Media/MediaTable';
import { dropzoneConfig, djsImageConfig, djsVideoConfig, isVideo } from '../helpers/Helpers';
import Breadcrumbs from './Breadcrumbs';

import 'dropzone/dist/min/dropzone.min.css';
import 'react-dropzone-component/styles/filepicker.css';

interface Props {}

interface State {
  media: MediaType[];
  param: string;
}

class Media extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      media: [],
      param: 'image',
    };
  }

  handleSuccess = (file: File, response: MediaType) => {
    this.setState({
      media: this.state.media.concat(response),
    });
  };

  handleError: ErrorCallback = (file, xhr) => {
    if (xhr instanceof Response && xhr.status && xhr.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
  };

  handleAdding = (file: File) => {
    if (file && file.type && isVideo(file.type)) {
      return this.setState({ param: 'video' });
    }
    return this.setState({ param: 'image' });
  };

  render() {
    const { media, param } = this.state;

    return (
      <Container>
        <Row mt={3}>
          <Column columnSm={12}>
            <Header type={1}>Media</Header>
          </Column>
        </Row>

        <Row mt={2}>
          <Column columnSm={12}>
            <Breadcrumbs />
          </Column>
        </Row>

        <Row>
          <Column columnSm={12}>
            <DropzoneComponent
              config={dropzoneConfig}
              djsConfig={param === 'image' ? djsImageConfig : djsVideoConfig}
              eventHandlers={{
                error: this.handleError,
                success: this.handleSuccess,
                addedfile: this.handleAdding,
              }}
            />
            {media.length > 0 && <MediaTable media={media} />}
          </Column>
        </Row>
      </Container>
    );
  }
}

export default Media;
