import React from 'react';
import { Box, Header } from '@generationtux/component-library';
import Breadcrumbs from '../Breadcrumbs';

interface Props {
  title?: string;
}

const PermalinksHeader = ({ title = 'Permalinks' }: Props) => {
  return (
    <Box>
      <Header type={1}>{title}</Header>
      <Breadcrumbs />
    </Box>
  );
};

export default PermalinksHeader;
