import React, { Component } from 'react';
import { DropzoneComponentProps } from 'react-dropzone-component';
import { Box, Header, Button, Text } from '@generationtux/component-library';

import { Media } from '../../../types';

import UploadMedia from './UploadMedia';
import MediaFields from './MediaFields';
import SearchMedia from './SearchMedia';

interface Props {
  minDisplayIndex: number;
  handleNewMedia: (media: Media) => void;
}

interface State {
  media: Media[];
  dropzone: DropzoneComponentProps | null;
  minDisplayIndex: number;
}

class AddMedia extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      media: [],
      dropzone: null,
      minDisplayIndex: Number(this.props.minDisplayIndex),
    };
  }

  getInitialState = () => ({
    media: [],
    dropzone: null,
    minDisplayIndex: 0,
  });

  handleNewMedia = (media: Media) =>
    this.setState(state => ({
      media: state.media.concat({
        ...media,
        display_index: this.getNextDisplayIndex(),
      }),
    }));

  handleNewMediaChange = (id: number, field: string, value: string) =>
    this.setState(state => ({
      media: state.media.map(media => {
        if (media.hosted_content_id !== id) {
          return media;
        }

        return {
          ...media,
          [field]: value,
        };
      }),
    }));

  handleCanceledMedia = (canceled: Media) =>
    this.setState(state => {
      const media = state.media
        .filter(({ hosted_content_id }) => canceled.hosted_content_id !== hosted_content_id)
        .map(media => this.getMediaWithAdjustedIndex(media, Number(canceled.display_index)));

      this.removeMediaFromDropzone(canceled);

      return { media };
    });

  addMedia = () => {
    this.props.handleNewMedia(this.state.media);

    this.state.media.forEach(this.removeMediaFromDropzone);

    this.setState({
      ...this.getInitialState(),
      minDisplayIndex: this.getNextDisplayIndex(),
    });
  };

  removeMediaFromDropzone = (media: Media) => {
    if (!media.file) {
      return;
    }

    // @ts-ignore: Property 'removeFile' does not exist on type 'DropzoneComponentProps'
    if (this.state.dropezone) this.state.dropzone.removeFile(media.file);
  };

  getMediaWithAdjustedIndex = (media: Media, index: number) => {
    const display_index = media!.display_index! > index ? media!.display_index! - 1 : media.display_index;

    return {
      ...media,
      display_index,
    };
  };

  allMediaIsLabeled = () => this.state.media.every(media => media.label && media.description);

  getNextDisplayIndex = () => this.state.minDisplayIndex + this.state.media.length;

  render() {
    const { media } = this.state;

    return (
      <Box mb={5}>
        <Header type={2} mb={3}>
          Add Media
        </Header>

        <UploadMedia handleNewMedia={this.handleNewMedia} init={dropzone => this.setState({ dropzone })} />

        <SearchMedia handleNewMedia={this.handleNewMedia} />

        <Box backgroundColor="grayLighter" mt={4} p={media.length > 0 ? 4 : 0}>
          {media.map((upload, index) => (
            <MediaFields
              key={index}
              media={upload}
              handleCancel={this.handleCanceledMedia}
              handleChange={this.handleNewMediaChange}
            />
          ))}

          {media.length > 0 && (
            <>
              <Button
                buttonType="info"
                onClick={() => this.addMedia()}
                disabled={!this.allMediaIsLabeled()}
                style={{ width: '100%' }}
              >
                Add Media
              </Button>
              {!this.allMediaIsLabeled() && (
                <Text mt={3} style={{ textAlign: 'center' }}>
                  You must select a label before you can add items.
                </Text>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  }
}

export default AddMedia;
