import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Box, Anchor, Text, space } from '@generationtux/component-library';

const BreadCrumbNav = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    display: inline-block;
    a {
      text-decoration: none;
    }
  }
`;

const makeBreadCrumbs = (pathname: string) => {
  return pathname
    .split('/')
    .slice(1)
    .reduce(
      (acc, val, index) => {
        if (val === '') {
          return acc;
        } else {
          return acc.concat({
            // drop `-` and capital case words
            title: val
              .toLowerCase()
              .split('-')
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ')
              .split('?')[0],
            // make the path for this slot
            path: pathname.split('/', index + 2).join('/'),
          });
        }
      },
      [
        {
          title: 'Home',
          path: '/',
        },
      ]
    );
};

const Breadcrumbs = ({ history }: RouteComponentProps) => {
  const breadCrumbs = makeBreadCrumbs(window.location.pathname);

  return (
    <Box>
      <BreadCrumbNav>
        {breadCrumbs.map((x, i) => (
          <li key={x.title}>
            {i !== 0 && i < breadCrumbs.length && <Text as="span">/</Text>}
            {` `}
            <Anchor mr={1} href={x.path} textColor="grayDarker">
              {x.title}
            </Anchor>
          </li>
        ))}
      </BreadCrumbNav>
    </Box>
  );
};

export default withRouter(Breadcrumbs);
