import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Plus, Button, Container, Header, Filter, Box, Text, Anchor, Message } from '@generationtux/component-library';

import { getBundles } from '../../services/BundlesApi';
import { Item } from '../../types';

import Table from '../styled/Table.style';
import Breadcrumbs from '../Breadcrumbs';
import { FormSearch } from '../FormSearch';

interface State {
  loadError: boolean;
  searchQuery: string;
  bundles: Item[];
  filteredBundles: Item[];
  colorFilters: string[];
}

class Bundles extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      bundles: [],
      filteredBundles: [],
      loadError: false,
      searchQuery: '',
      colorFilters: [],
    };
  }

  async componentDidMount() {
    try {
      const bundlesRes = await getBundles();

      if (bundlesRes.status && bundlesRes.status !== 200 && bundlesRes.status !== 201)
        throw new Error(`Bad response status (${bundlesRes.statusText}) loading bundles.`);

      const data: Item[] = await bundlesRes.json();

      this.setState({
        bundles: data.filter(b => b.is_active),
      });
    } catch (e) {
      this.setState({
        loadError: true,
      });
      console.error(e);
    }
  }

  handleSearchChange = (value: string) => {
    const filteredBundles = this.state.bundles.filter(
      (bundle: Item) => bundle!.display_name!.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1
    );
    this.setState({
      filteredBundles,
      searchQuery: value,
    });
  };

  toggleColorFilter = (name: string) => {
    const colorFilters = this.state.colorFilters.includes(name)
      ? this.state.colorFilters.filter(c => c !== name)
      : [...this.state.colorFilters, name];

    const filteredBundles = this.state.bundles.filter((bundle: Item) => {
      if (colorFilters.length > 0) {
        return bundle!.color!.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) !== -1;
      }
      return false;
    });

    this.setState({
      filteredBundles,
      colorFilters,
    });
  };

  render() {
    const { history } = this.props;
    const { loadError, searchQuery, colorFilters, bundles, filteredBundles } = this.state;

    return (
      <>
        <Container>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Header type={1}>Bundles</Header>

              <Breadcrumbs />
            </Box>
            <Button buttonType="primary" mt={3} buttonIcon={<Plus />} href={'/bundles/create'}>
              New Bundle
            </Button>
          </Box>
        </Container>

        {loadError ? (
          <Container>
            <Message messageType="error" msg={'Error loading bundles.'} />
          </Container>
        ) : (
          <>
            <FormSearch
              elementWidth="100%"
              label="Search"
              combineLabel
              name="searchQuery"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleSearchChange(e.target.value)}
              placeholder="Search for a Bundle"
            />
            <Container>
              <Header type={2} size={5} mb={2}>
                Filter by Color
              </Header>
              <Box display="flex" justifyContent="flex-start" mb={3}>
                {['blue', 'black', 'tan', 'brown', 'grey', 'white', 'green', 'burgundy'].map(color => (
                  <Filter
                    id={`${color}FilterBtn`}
                    key={color}
                    onClick={() => this.toggleColorFilter(color)}
                    isActive={colorFilters.includes(color)}
                    filterColor={color}
                    filterLabel={color.charAt(0).toUpperCase() + color.substr(1).toLowerCase()}
                    mr={2}
                    mb={2}
                  />
                ))}
              </Box>

              <Table>
                <thead>
                  <tr>
                    <th>
                      <Text>Display Name</Text>
                    </th>
                    <th>
                      <Text>Type</Text>
                    </th>
                    <th>
                      <Text>Color</Text>
                    </th>
                    <th>
                      <Text>Cost</Text>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {bundles.length > 0 &&
                    (searchQuery.length <= 0 && filteredBundles.length <= 0) &&
                    bundles.map(bundle => (
                      <tr key={bundle.id}>
                        <td>
                          <Text>{bundle.display_name}</Text>
                        </td>
                        <td>
                          <Text>{bundle.type}</Text>
                        </td>
                        <td>
                          <Text>{bundle.color}</Text>
                        </td>
                        <td>
                          <Text>$ {bundle.cost}</Text>
                        </td>
                        <td>
                          <Text>
                            <Anchor style={{ cursor: 'pointer' }} href={`/bundles/${bundle.id}`}>
                              Edit
                            </Anchor>
                          </Text>
                        </td>
                      </tr>
                    ))}

                  {bundles.length > 0 &&
                    filteredBundles.length > 0 &&
                    filteredBundles.map(bundle => (
                      <tr key={bundle.id}>
                        <td>
                          <Text>{bundle.display_name}</Text>
                        </td>
                        <td>
                          <Text>{bundle.type}</Text>
                        </td>
                        <td>
                          <Text>{bundle.color}</Text>
                        </td>
                        <td>
                          <Text>$ {bundle.cost}</Text>
                        </td>
                        <td>
                          <Text>
                            <Anchor style={{ cursor: 'pointer' }} href={`/bundles/${bundle.id}`}>
                              Edit
                            </Anchor>
                          </Text>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Button buttonType="info" size="sm" mt={3} display="inline-block" href={'/bundles/create'}>
                Create a Bundle
              </Button>
            </Container>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Bundles);
