import Auth from './Auth';
import { JsonResponse } from '../types';

export const fetchMiddleware = async <T extends any = any>(url: string, data = {}) => {
  const response: JsonResponse<T> = await fetch(
    process.env.REACT_APP_ORGANIZATION_ID === '2'
      ? `${url}${url.indexOf('?') === -1 ? '?' : '&'}organization_id=2`
      : url,
    {
      ...data,
      headers: Auth.getHeaders(),
    }
  );

  return response;
};
