import React from 'react';
import { Box, Text } from '@generationtux/component-library';

interface Props {
  str: string;
  highlight: string;
}

const PermalinkHighlight = ({ str, highlight }: Props) => {
  const data = str.includes(highlight) ? (
    str.split(highlight).map((x, index) => (
      <React.Fragment key={index}>
        <Text as="span" textColor="gray">
          {x}
        </Text>
        {index !== str.split(highlight).length - 1 && (
          <Text as="strong" textColor="grayDarker">
            {highlight}
          </Text>
        )}
      </React.Fragment>
    ))
  ) : (
    <Text textColor="grayDarker">{str}</Text>
  );

  return (
    <Box display="flex" flexWrap="wrap" style={{ wordBreak: 'break-all' }}>
      {data}
    </Box>
  );
};

export default PermalinkHighlight;
