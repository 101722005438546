import styled from 'styled-components';
import { variables, space } from '@generationtux/component-library';

export default styled.table`
  width: 100%;
  border: border-collapse;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;

  tr:nth-child(2n) {
    background: ${props => variables(props).grayLighter};
  }

  th {
    border-bottom: 1px solid ${props => variables(props).gray};
  }

  td,
  th {
    padding: ${space[2]}px ${space[2]}px;
  }
`;
