import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { LogoGentux, LogoMenguin, Box, Anchor, breakpoints, space, Button } from '@generationtux/component-library';

import Auth from '../services/Auth';

const NavMainPrimary = styled(Box)`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    > li > a {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: black;
        transform: scaleX(0);
        transform-origin: bottom left;
        transition: 0.2s all ease;
      }
      &:hover {
        cursor: pointer;
        text-decoration: none;
        &:after {
          transform: scaleX(1);
        }
      }
    }

    li > a.active {
      color: black;
      &:after {
        transform: scaleX(1);
      }
    }
  }
  @media (min-width: ${breakpoints['md']}) {
    ul {
      display: flex;
      > li {
        margin-right: ${space[4] + 'px'};
      }
      > li > a {
        height: 80px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

interface State {
  modulesDropdown: boolean;
}

class Navbar extends Component<RouteComponentProps, State> {
  state = {
    modulesDropdown: false,
  };

  render() {
    const { history, location } = this.props;

    return (
      <header>
        <nav>
          <Box display="flex" justifyContent="space-between" alignItems="stretch" px={[3, null, null, 4]}>
            <Anchor href={'/'} py={[2, 2, 3, 3, 3, 3]} display="flex" style={{ alignItems: 'center' }}>
              {process.env.REACT_APP_ORGANIZATION_ID === '1' ? <LogoGentux width="40" /> : <LogoMenguin width="40" />}
            </Anchor>

            <NavMainPrimary
              pl={[2, null, null, 4]}
              style={{ flexGrow: '1' }}
              display="flex"
              alignItems="stretch"
              justifyContent="flex-start"
            >
              <Box p={[4, '', '', 0]} backgroundColor="white">
                <ul style={{ margin: 0, padding: 0 }}>
                  <li style={{ position: 'relative' }}>
                    <Anchor
                      href={'/pages'}
                      py={1}
                      display="block"
                      style={{ whiteSpace: 'nowrap' }}
                      textColor={`${location.pathname.includes('/pages') ? 'black' : 'grayDark'}`}
                    >
                      Pages
                    </Anchor>
                  </li>
                  <li style={{ position: 'relative' }}>
                    <Anchor
                      href={'/media'}
                      py={1}
                      display="block"
                      style={{ whiteSpace: 'nowrap' }}
                      textColor={`${location.pathname.includes('/media') ? 'black' : 'grayDark'}`}
                    >
                      Media Upload
                    </Anchor>
                  </li>
                  <li style={{ position: 'relative' }}>
                    <Anchor
                      href={'/products'}
                      py={1}
                      display="block"
                      style={{ whiteSpace: 'nowrap' }}
                      textColor={`${location.pathname.includes('/products') ? 'black' : 'grayDark'}`}
                    >
                      Products
                    </Anchor>
                  </li>
                  <li style={{ position: 'relative' }}>
                    <Anchor
                      href={'/bundles'}
                      py={1}
                      display="block"
                      style={{ whiteSpace: 'nowrap' }}
                      textColor={`${location.pathname.includes('/bundles') ? 'black' : 'grayDark'}`}
                    >
                      Bundles
                    </Anchor>
                  </li>
                  <li style={{ position: 'relative' }}>
                    <Anchor
                      href={'/collections'}
                      py={1}
                      display="block"
                      style={{ whiteSpace: 'nowrap' }}
                      textColor={`${location.pathname.includes('/collections') ? 'black' : 'grayDark'}`}
                    >
                      Collections
                    </Anchor>
                  </li>
                  <li style={{ position: 'relative' }}>
                    <Anchor
                      href={'/permalinks'}
                      py={1}
                      display="block"
                      style={{ whiteSpace: 'nowrap' }}
                      textColor={`${location.pathname.includes('/permalinks') ? 'black' : 'grayDark'}`}
                    >
                      Permalinks
                    </Anchor>
                  </li>
                </ul>
              </Box>
            </NavMainPrimary>
            <Box display="flex">
              <Button buttonType="link" onClick={() => Auth.logout()}>
                Logout
              </Button>
            </Box>
          </Box>
        </nav>
      </header>
    );
  }
}

export default withRouter(Navbar);
