import React from 'react';

import { Container, Header } from '@generationtux/component-library';

const Home = () => (
  <Container>
    <Header type={1} mt={4}>
      Home
    </Header>
  </Container>
);

export default Home;
