import React from 'react';
import styled from 'styled-components';
import { Box, Spinner, breakpoints } from '@generationtux/component-library';

// TODO: eventually move this to component library

type Props = {
  children?: React.ReactNode;
  height?: Array<String>;
  type?: String;
};

const CustomContainer = styled(Box)`
  height: ${props => props.height[0]};

  @media (min-width: ${breakpoints['xs']}) {
    height: ${props => props.height[1]};
  }

  @media (min-width: ${breakpoints['sm']}) {
    height: ${props => props.height[2]};
  }

  @media (min-width: ${breakpoints['md']}) {
    height: ${props => props.height[3]};
  }

  @media (min-width: ${breakpoints['lg']}) {
    height: ${props => props.height[4]};
  }

  @media (min-width: ${breakpoints['xl']}) {
    height: ${props => props.height[5]};
  }
`;

const defaultHeight = ['80vh', '80vh', '80vh', '80vh', '80vh', '80vh'];

const SpinnerContainer: React.StatelessComponent<Props> = ({
  children = null,
  height = defaultHeight,
  type = 'minimal',
}) => (
  <CustomContainer display="flex" justifyContent="center" alignItems="center" height={height}>
    <Box>
      <Spinner type={type} style={{ height: 'auto' }} />
      {children}
    </Box>
  </CustomContainer>
);

export default SpinnerContainer;
