import { PageInput } from '../types';
import { fetchMiddleware } from './fetchMiddleware';

export const graphQL = async (query: string, variables = {}) =>
  await fetchMiddleware(
    `${process.env.REACT_APP_MEDIA_SERVICE_URL}/graphql?organization_id=${process.env.REACT_APP_ORGANIZATION_ID}`,
    {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables,
      }),
    }
  );

// pages
export const getPageQuery = async (id: number) =>
  await graphQL(
    `query getPage(
    $pageId: Int!) {
      page(id: $pageId) {
        slug,
        heading,
        subHeading,
        title,
        metaDescription,
      }
    }`,
    {
      pageId: id,
    }
  );

export const getPagesQuery = async () =>
  await graphQL(
    `query getPagesQuery {
      pages {
        id,
        slug,
        heading,
        subHeading,
        title,
        metaDescription,
      }
    }`
  );

export const createPageQuery = async (input: PageInput) =>
  await graphQL(
    `mutation createPage(
        $input: CreatePageInput!) {
          createPage(input: $input) {
            id,
          }
        }`,
    { ...input }
  );

export const updatePageQuery = async (input: PageInput) =>
  await graphQL(
    `mutation updatePage(
      $id: Int!,
      $input: UpdatePageInput!) {
        updatePage(id: $id, input: $input) {
          id,
        }
      }`,
    { ...input }
  );

// permalinks
export const getPermalink = async (id: number) =>
  await graphQL(
    `query Permalink(
            $id: Int!
          ) {
            Permalink(id: $id) {
              id
              url
              code
            }
          }`,
    {
      id: id,
    }
  );

export const getPermalinksBySku = async (sku: string) =>
  await graphQL(
    `query PermalinksBySku(
    $sku: String!
  ) {
    PermalinksBySku(sku: $sku, orgId: ${process.env.REACT_APP_ORGANIZATION_ID}) {
      id
      url
      code
    }
  }`,
    {
      sku,
    }
  );

export const getPermalinksBySearch = async (search: string) =>
  await graphQL(
    `query PermalinksBySearch(
    $search: String!
  ) {
    PermalinksBySearch(search: $search, orgId: ${process.env.REACT_APP_ORGANIZATION_ID}) {
      id
      url
      code
    }
  }`,
    {
      search,
    }
  );

export const updateAllPermalinkSkus = async (oldSku: string, newSku: string) =>
  await graphQL(
    `mutation UpdateAllPermalinkSkus(
    $oldSku: String!,
    $newSku: String!
  ) {
    UpdateAllPermalinkSkus(oldSku: $oldSku, newSku: $newSku, orgId: ${process.env.REACT_APP_ORGANIZATION_ID}) {
      id
      url
      code
    }
  }`,
    {
      oldSku: oldSku,
      newSku: newSku,
    }
  );

export const createPermalink = async (url: string) =>
  await graphQL(
    `mutation CreatePermalink(
        $url: String!
      ) {
          CreatePermalink(url: $url, orgId: ${process.env.REACT_APP_ORGANIZATION_ID}) {
          id
          url
          code
        }
      }`,
    {
      url,
    }
  );

export const updatePermalink = async (id: number, url: string) =>
  await graphQL(
    `mutation UpdatePermalink(
        $id: Int!,
        $url: String!
      ) {
        UpdatePermalink(id: $id, url: $url) {
          id
          url
          code
        }
      }`,
    {
      id,
      url,
    }
  );

export const deletePermalink = async (id: number) =>
  await graphQL(
    `mutation DeletePermalink(
        $id: Int!
      ) {
        DeletePermalink(id: $id)
      }`,
    { id: id }
  );
