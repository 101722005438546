import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Box,
  Button,
  Header,
  Container,
  FormInput,
  Label,
  Message,
  Text,
  Spinner,
  Anchor,
  ArrowLeft,
} from '@generationtux/component-library';

import { Permalink } from '../../types';
import { getPermalink, updatePermalink, deletePermalink, createPermalink } from '../../services/MediaApi';
import { constructPermalinkUrl } from '../../helpers/Helpers';
import CopyToClipboard from 'react-copy-to-clipboard';

import PermalinksHeader from './PermalinksHeader';

interface Props extends RouteComponentProps<{ id?: string }> {
  creating: boolean;
}

interface State {
  permalink: Permalink | null;
  tempUrl: string;
  copied: boolean;
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  success: boolean;
  error: boolean;
}

class PermalinkForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      permalink: null,
      tempUrl: '',
      copied: false,
      loading: true,
      saving: false,
      deleting: false,
      success: false,
      error: false,
    };
  }

  async componentDidMount() {
    if (!this.props.creating) {
      const { id } = this.props.match.params;

      try {
        const mediaRes = await getPermalink(Number(id));

        if (mediaRes.status && mediaRes.status !== 200 && mediaRes.status !== 201)
          throw new Error(`Bad response status (${mediaRes.statusText}) loading permalink.`);

        const data = await mediaRes.json();

        if (data.data && data.data.Permalink) {
          this.setState(() => ({
            permalink: data.data.Permalink,
            tempUrl: data.data.Permalink.url,
          }));
        } else {
          this.setState(() => ({ error: true }));
        }
      } catch (e) {
        this.setState(() => ({ error: true }));
      }
    }
    this.setState(() => ({ loading: false }));
  }

  handleCreateOrUpdate = async () => (this.props.creating ? this.handleCreate() : this.handleUpdate());

  handleCreate = async () => {
    this.setState(() => ({ saving: true, error: false }));

    try {
      const mediaRes = await createPermalink(this.state.tempUrl);

      if (mediaRes.status && mediaRes.status !== 200 && mediaRes.status !== 201)
        throw new Error(`Bad response status (${mediaRes.statusText}) creating permalink.`);

      const data = await mediaRes.json();

      if (data.data && data.data.CreatePermalink) {
        this.setState(() => ({
          permalink: data.data.CreatePermalink,
          success: true,
          tempUrl: data.data.CreatePermalink.url,
        }));

        setTimeout(() => this.setState(() => ({ success: false })), 4000);

        this.props.history.push(`/permalinks/${data.data.CreatePermalink.id}`);
      } else {
        this.setState(() => ({ error: true }));
      }
    } catch (e) {
      this.setState(() => ({ error: true }));
      console.error(e);
    }

    this.setState(() => ({ saving: false }));
  };

  handleUpdate = async () => {
    this.setState(() => ({ saving: true, error: false }));

    const { permalink, tempUrl } = this.state;
    const { id } = permalink!;

    try {
      const mediaRes = await updatePermalink(id, tempUrl);

      if (mediaRes.status && mediaRes.status !== 200 && mediaRes.status !== 201)
        throw new Error(`Bad response status (${mediaRes.statusText}) updating permalink.`);

      const data = await mediaRes.json();

      if (data.data && data.data.UpdatePermalink) {
        this.setState(() => ({
          permalink: data.data.UpdatePermalink,
          success: true,
          tempUrl: data.data.UpdatePermalink.url,
        }));
      } else {
        this.setState(() => ({ error: true }));
      }
    } catch (e) {
      this.setState(() => ({ error: true }));
      console.error(e);
    }

    this.setState(() => ({ saving: false }));
  };

  handleChange = (tempUrl: string) => this.setState(() => ({ tempUrl }));

  handleDeletePermalink = async (id: number) => {
    this.setState(() => ({ deleting: true, error: false }));

    try {
      const mediaRes = await deletePermalink(id);

      if (mediaRes.status && mediaRes.status !== 200 && mediaRes.status !== 201)
        throw new Error(`Bad response status (${mediaRes.statusText}) deleting permalink.`);

      const data = await mediaRes.json();

      if (data.data) {
        window.location.href = '/permalinks';
      } else {
        this.setState(() => ({ error: true }));
      }
    } catch (e) {
      this.setState(() => ({ error: true }));
      console.error(e);
    }

    this.setState(() => ({ deleting: false }));
  };

  render() {
    if (this.state.loading) {
      return <Spinner type="minimal" />;
    }

    const { permalink, tempUrl, error, saving, success, deleting } = this.state;
    const isCreating = this.props.creating;

    return (
      <Container>
        <Box mb={4}>
          <PermalinksHeader title={isCreating ? 'Create Permalink' : 'Edit Permalink'} />
        </Box>

        {!isCreating && (
          <>
            <Box mb={3}>
              <Header type={2} size={3} mb={2}>
                Permalink
              </Header>

              <Text textColor="grayDark">
                Copy, update or delete this permalink. The permalink is what should{' '}
                <Text as="strong" textColor="grayDarker">
                  be shared
                </Text>{' '}
                to the public.
              </Text>
            </Box>

            <Box px={4} py={4} shadowLevel={1} mb={5}>
              <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Anchor
                  p={2}
                  center
                  style={{
                    wordWrap: 'break-all',
                    overflowWrap: 'break-all',
                    flexGrow: 1,
                  }}
                  target="_blank"
                  href={`http://${constructPermalinkUrl(permalink!.code)}`}
                >
                  {constructPermalinkUrl(permalink!.code)}
                </Anchor>

                <CopyToClipboard text={constructPermalinkUrl(permalink!.code)!}>
                  <Button buttonType="info" onClick={() => this.setState({ copied: true })}>
                    {this.state.copied ? `Copied!` : `Copy Permalink`}
                  </Button>
                </CopyToClipboard>
              </Box>
            </Box>
          </>
        )}

        <FormInput
          label="Original Url"
          description={
            <Text textColor="grayDark">
              This is the original url that the permalink will link to. This url should{' '}
              <Text as="strong" textColor="grayDarker">
                not
              </Text>{' '}
              be shared publicly.
            </Text>
          }
          name="Url"
          value={tempUrl}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e.target.value)}
          placeholder="Enter url (e.g. https://www.generationtux.com/home-try-on)"
          elementWidth="100%"
          mb={3}
        />

        <Box display="flex">
          <Button
            buttonType="primary"
            size="sm"
            onClick={() => this.handleCreateOrUpdate()}
            disabled={!tempUrl || saving}
            mr={2}
          >
            {isCreating ? 'Create' : 'Update'}
          </Button>

          {!isCreating && tempUrl !== permalink!.url && (
            <Button
              buttonType="primary"
              outline
              size="sm"
              onClick={() => this.setState(state => ({ tempUrl: state.permalink!.url }))}
              disabled={!tempUrl || saving}
              mr={2}
            >
              Cancel
            </Button>
          )}

          {!isCreating && (
            <Button
              buttonType="danger"
              outline
              size="sm"
              onClick={() => this.handleDeletePermalink(permalink!.id)}
              disabled={deleting}
              mr={2}
            >
              Delete
            </Button>
          )}
        </Box>

        <Box mb={5}>
          {success && <Message messageType="success" msg="Success!" />}
          {error && <Message messageType="error" msg="An occurred. Please try again." />}
        </Box>

        <Button
          outline
          onClick={() => this.props.history.push(`/permalinks`)}
          buttonIcon={<ArrowLeft />}
          buttonIconPosition="left"
        >
          Back to permalinks
        </Button>
      </Container>
    );
  }
}

export default PermalinkForm;
