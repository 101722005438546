import React from 'react';
import { Trashcan, Header, Box, Text, Button } from '@generationtux/component-library';

import { Media } from '../../../types';
import MediaThumbnail from './MediaThumnail';

interface Props {
  index: number;
  media: Media;
  handleDelete: (media: Media) => void;
}

export default ({ index, media, handleDelete }: Props) => (
  <Box display="flex" alignItems="stretch" mb={2}>
    <Box display="flex" alignItems="center" p={2} mr={'1px'} backgroundColor="grayLighter" style={{ flexGrow: 1 }}>
      <Text mr={2} textColor="gray">
        {index + 1}
      </Text>

      <Box borderWidth={1} borderColor="gray" style={{ lineHeight: 0 }}>
        <MediaThumbnail media={media} />
      </Box>

      <Box mx={2} style={{ flexGrow: 1 }}>
        <Header as="p" size={5}>
          {media.label}
        </Header>
        <Text size="sm" textColor="grayDark" style={{ wordBreak: 'break-all' }}>
          {media.description}
        </Text>
      </Box>
    </Box>

    <Button
      buttonType="danger"
      size="sm"
      onClick={() => handleDelete(media)}
      buttonIcon={<Trashcan />}
      buttonIconPosition="right"
    />
  </Box>
);
