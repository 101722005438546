import React from 'react';
import { Id } from 'koeus';
import Table from '../../styled/Table.style';
import { Text, Button, Anchor } from '@generationtux/component-library';
import { Media } from '../../../types';
import MediaThumnail from './MediaThumnail';

interface Props {
  media: Media[];
  onRowClick?: (media: Media) => void;
}

const MediaTable = ({ media, onRowClick = Id }: Props) => (
  <div>
    <Table>
      <thead>
        <tr>
          <th>
            <Text size="sm">Thumbnail</Text>
          </th>
          <th>
            <Text size="sm">Id</Text>
          </th>
          <th>
            <Text size="sm">Filename</Text>
          </th>
          <th>
            <Text size="sm">URL</Text>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {media.map(media => (
          <tr key={media.hosted_content_store_id}>
            <td>
              <MediaThumnail media={media} />
            </td>
            <td>
              <Text size="sm">{media.hosted_content_store_id}</Text>
            </td>
            <td style={{ wordBreak: 'break-word' }}>
              <Text size="sm">{media.description}</Text>
            </td>
            <td style={{ wordBreak: 'break-word' }}>
              <Anchor size="sm" href={media.url}>
                {media.url}
              </Anchor>
            </td>
            <td>
              <Button buttonType="info" size="sm" onClick={() => onRowClick(media)}>
                Select
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default MediaTable;
