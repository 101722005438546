import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Column,
  Container,
  Header,
  Plus,
  FormInput,
  Message,
  Row,
  Spinner,
  Box,
  Line,
  Button,
} from '@generationtux/component-library';

import { BlockoutDate, Item, ProductFormState, ProductMedia } from '../../types';
import { getProduct, getSwatches, createProduct, updateProduct, setProductStatus } from '../../services/ProductsApi';

import AddMedia from '../shared/Media/AddMedia';
import MediaList from '../shared/Media/MediaList';

import Breadcrumbs from '../Breadcrumbs';
import ProductInfo from './ProductInfo';
import { buildMedia, calcDisplayIndex, getErrorMessage, getResponseBody } from '../../helpers/Helpers';
import { RemovableBlock } from '../shared/RemovableBlock';

export const SWATCH_ACCESSORY = 'Swatch (accessory)';
export const SWATCH_SUIT_TUX = 'Swatch (suit/tux)';

interface Props extends RouteComponentProps<{ id?: string }> {
  creating: boolean;
}

interface State extends ProductFormState {
  media: ProductMedia[];
}

const getSubmitText = (
  creating: boolean,
  submitting: boolean,
  updateProductSuccess: boolean,
) => {
  if (updateProductSuccess) {
    return 'Success!';
  }

  if (submitting) {
    return creating ? 'Creating...' : 'Updating...';
  }

  return creating ? 'Create' : 'Save Changes';
};

class ProductForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      loadingError: false,
      product: {
        id: 0,
        category: '',
        color: '',
        cost: '',
        details: [],
        display_index: '',
        display_name: '',
        displayable: true,
        is_active: true,
        pattern: '',
        rental_terms: '',
        short_description: '',
        sku: '',
        type: '',
        swatch: null,
        blockout_dates: [],
        sub_category: '',
        url_slug: '',
        subtype: '',
      },
      media: [],
      updateProductError: false,
      updateProductErrorMessage: '',
      submitting: false,
      updateProductSuccess: false,
      skuErr: false,
      urlSlugErr: false,
      displayNameErr: false,
      costErr: false,
      patternErr: false,
      categoryErr: false,
      typeErr: false,
      colorErr: false,
      detailsErr: false,
      swatchSearch: '',
      swatches: [],
      swatchSearchResults: [],
      subCategoryAttributeError: false,
      subtypeAttributeError: false,
    };
  }

  async componentDidMount() {
    if (!this.props.creating) {
      try {
        const productsRes = await getProduct(this.props.match.params.id!);

        if (productsRes.status && productsRes.status !== 200 && productsRes.status !== 201)
          throw new Error(`Bad response status (${productsRes.statusText}) loading product.`);

        const product = await productsRes.json();

        if (product && product.media) {
          this.setState({ product: product, media: product.media });

          const swatchesRes = await getSwatches();

          if (swatchesRes.status && swatchesRes.status !== 200 && swatchesRes.status !== 201)
            throw new Error(`Bad response status (${swatchesRes.statusText}) loading swatches.`);

          const swatches = await swatchesRes.json();

          this.setState({ swatches: swatches });
        } else {
          this.setState({ loadingError: true });
        }
      } catch (e) {
        this.setState({ loadingError: true });
        console.error(e);
      }
    }

    this.setState({ loading: false });
  }

  validateRequest() {
    let skuErr = false;
    let urlSlugErr = false;
    let displayNameErr = false;
    let costErr = false;
    let rentalTerms = this.state.product.rental_terms;
    let shortDescription = this.state.product.short_description;
    let patternErr = false;
    let categoryErr = false;
    let typeErr = false;
    let colorErr = false;
    let detailsErr = false;
    let subCategoryAttributeError = false;
    let subtypeAttributeError = false;

    if (this.props.creating && this.state.product.sku === '') skuErr = true;
    if (this.props.creating && this.state.product.url_slug === '') urlSlugErr = true;
    if (this.state.product.display_name === '') displayNameErr = true;
    if (this.state.product.cost === '') costErr = true;
    if (!rentalTerms) rentalTerms = '';
    if (!shortDescription) shortDescription = '';
    if (this.state.product.pattern === '') patternErr = true;
    if (this.state.product.category === '') categoryErr = true;
    if (this.state.product.type === '') typeErr = true;
    if (this.state.product.color === '') colorErr = true;
    if (this.state.product.details!.length === 0) detailsErr = true;
    if (
      this.state.product.sub_category === '' &&
      (this.state.product.category === 'Tie' || this.state.product.category === 'Vest')
    )
      subCategoryAttributeError = true;
    if (
      !this.state.product.subtype &&
      this.state.product.category === 'Tie'
    )
      subtypeAttributeError = true;

    this.setState({
      skuErr,
      urlSlugErr,
      displayNameErr,
      costErr,
      patternErr,
      categoryErr,
      typeErr,
      colorErr,
      detailsErr,
      subCategoryAttributeError,
      subtypeAttributeError,
      product: {
        ...this.state.product,
        rental_terms: rentalTerms,
        short_description: shortDescription,
      },
    });

    return !(
      skuErr ||
      urlSlugErr ||
      displayNameErr ||
      costErr ||
      patternErr ||
      categoryErr ||
      typeErr ||
      colorErr ||
      detailsErr ||
      subCategoryAttributeError ||
      subtypeAttributeError
    );
  }

  createOrUpdate = () => {
    if (!this.validateRequest()) {
      return;
    }

    this.setState(state => ({
      submitting: true,
      updateProductError: false,
      updateProductErrorMessage: '',
      product: {
        ...state.product,
        media: buildMedia(state.media),
      },
    }), async () => {
      if (this.props.creating) {
        this.createProduct();
      } else {
        this.updateProduct();
      }
    })
  };

  createProduct = async () => {
    try {
      const productRes = await createProduct(this.state.product);

      if (productRes.status && productRes.status !== 200 && productRes.status !== 201) {
        const errorBody = await getResponseBody(productRes);

        throw new Error(`Bad response status (${productRes.statusText}) creating product: ${getErrorMessage(errorBody)}`);
      }

      const data = await productRes.json();

      this.setState({ updateProductSuccess: true });

      setTimeout(() => this.setState(() => ({ updateProductSuccess: false })), 2000);

      this.props.history.push(`/products/${data.id}`);
    } catch (e) {
      this.setState({
        updateProductError: true,
        updateProductErrorMessage: getErrorMessage(e),
      });

      console.error(e);
    } finally {
      this.setState({ submitting: false });
    }
  }

  updateProduct = async () => {
    try {
      const productRes = await updateProduct(this.props.match.params.id!, this.state.product);

      if (productRes.status && productRes.status !== 200 && productRes.status !== 201) {
        const errorBody = await getResponseBody(productRes);

        throw new Error(`Bad response status (${productRes.statusText}) updating product: ${getErrorMessage(errorBody)}`);
      }

      await productRes.json();

      this.setState({ updateProductSuccess: true });

      setTimeout(() => this.setState({ updateProductSuccess: false }), 2000);
    } catch (e) {
      this.setState({
        updateProductError: true,
        updateProductErrorMessage: getErrorMessage(e),
      });

      console.error(e);
    } finally {
      this.setState({ submitting: false });
    }
  };

  activateProduct = (isActive: boolean) => {
    if (isActive || window.confirm('Are you sure you want to delete this product?')) {
      this.setState(
        () => ({
          submitting: true,
          updateProductError: false,
          updateProductErrorMessage: '',
        }),
        async () => {
          try {
            const productRes = await setProductStatus(this.state.product.id, isActive);

            if (productRes.status && productRes.status !== 200 && productRes.status !== 201) {
              const errorBody = await getResponseBody(productRes);

              throw new Error(`Bad response status (${productRes.statusText}) updating product: ${getErrorMessage(errorBody)}`);
            }

            await productRes.json();

            this.setState(() => ({
              product: { ...this.state.product, is_active: isActive },
              submitting: false,
            }));
          } catch (e) {
            this.setState(() => ({
              updateProductError: true,
              updateProductErrorMessage: getErrorMessage(e),
              submitting: false,
            }));
          }
        }
      );
    }
  };

  handleProductChange = (field: string, value: string) =>
    this.setState({
      product: {
        ...this.state.product,
        [field]: value,
      },
    });

  addBlockoutDate = (blockoutDate: BlockoutDate) =>
    this.setState({
      product: {
        ...this.state.product,
        blockout_dates: this.state.product.blockout_dates!.concat(blockoutDate),
      },
    });

  removeBlockoutDate = (index: number) => {
    const blockoutDates = this.state.product.blockout_dates!.filter((date, i) => index !== i);
    this.setState({
      product: { ...this.state.product, blockout_dates: blockoutDates },
    });
  };

  addDetailInput = () => {
    this.setState(state => ({
      product: {
        ...state.product,
        details: state.product.details!.concat({ detail: '' }),
      },
    }));
  };

  handleUpdateDetail = (index: number, value: string) => {
    const details = this.state.product!.details!.map((detail, i) => {
      if (index === i) {
        return { ...detail, detail: value };
      }
      return detail;
    });
    this.setState({
      product: { ...this.state.product, details },
    });
  };

  removeDetail = (index: number) =>
    this.setState({
      product: {
        ...this.state.product,
        details: this.state.product.details!.filter((detail, i) => index !== i),
      },
    });

  handleNewMedia = (newMedia: ProductMedia) =>
    this.setState({
      media: this.state.media!.concat(newMedia),
    });

  handleRemoveMedia = (media: ProductMedia) => {
    const updatedMedia = new Set(this.state.media);
    updatedMedia.delete(media);
    this.setState({ media: Array.from(updatedMedia) });
  };

  handleSwatchSearchChange = (value: string) =>
    this.setState({
      swatchSearch: value,
      swatchSearchResults: this.state.swatches!.filter(
        s =>
          s!.display_name!.toLowerCase().includes(value.toLowerCase()) ||
          s.sku.toLowerCase().includes(value.toLowerCase())
      ),
    });

  addSwatch = (swatch: Item) =>
    this.setState({
      product: {
        ...this.state.product,
        swatch,
      },
      swatchSearch: '',
      swatchSearchResults: [],
    });

  removeSwatch = () =>
    this.setState({
      product: {
        ...this.state.product,
        swatch: null,
      },
    });

  getAPIErrorMessage = () => {
    if (!this.state.updateProductErrorMessage) {
      return "We encountered a problem updating this product.";
    }

    return this.state.updateProductErrorMessage;
  };

  render() {
    const {
      loading,
      loadingError,
      product,
      updateProductError,
      submitting,
      updateProductSuccess,
      skuErr,
      urlSlugErr,
      displayNameErr,
      costErr,
      patternErr,
      categoryErr,
      typeErr,
      colorErr,
      detailsErr,
      subCategoryAttributeError,
      subtypeAttributeError,
      swatchSearch,
      swatchSearchResults,
      media,
    } = this.state;

    const { creating } = this.props;

    if (loading) return <Spinner />;

    return (
      <Container>
        {loadingError && <Message shadowLevel={0} messageType="error" msg="Error loading product. Please try again." />}

        <Box mb={4}>
          <Breadcrumbs />
        </Box>
        <Row justifyContent="space-between" alignItems="flex-start" flexWrap="wrap" flexDirection="row-reverse">
          <Column
            column={12}
            columnSm={4}
            columnMd={5}
            columnLg={4}
            style={{ position: 'sticky', top: 32, zIndex: 1000 }}
          >
            <Box backgroundColor="white" shadowLevel={3} p={[3, 3, 4, 4, 4, 4]} mb={4}>
              <Header type={1} size={3} mb={3} textTransform="none">
                {product.display_name}
              </Header>

              <Box display="flex" flexDirection="column" mt={3}>
                <Button buttonType="info" mb={3} onClick={() => this.createOrUpdate()}>
                  {getSubmitText(creating, submitting, updateProductSuccess)}
                </Button>

                {product.is_active ? (
                  <Button buttonType="danger" outline onClick={() => this.activateProduct(false)}>
                    Delete Product
                  </Button>
                ) : (
                  <Button buttonType="success" onClick={() => this.activateProduct(true)}>
                    Activate Product
                  </Button>
                )}
              </Box>

              {updateProductError && (
                <Message mt={3} messageType="error" msg={this.getAPIErrorMessage()} />
              )}
            </Box>
          </Column>
          <Column column={12} columnSm={8} columnMd={6} columnLg={7}>
            <ProductInfo
              product={product}
              handleChange={this.handleProductChange}
              handleUpdateDetail={this.handleUpdateDetail}
              removeDetail={this.removeDetail}
              addDetailInput={this.addDetailInput}
              addBlockoutDate={this.addBlockoutDate}
              removeBlockoutDate={this.removeBlockoutDate}
              createOrUpdate={this.createOrUpdate}
              updateProductError={updateProductError}
              submitting={submitting}
              updateProductSuccess={updateProductSuccess}
              creating={creating}
              skuErr={skuErr}
              urlSlugErr={urlSlugErr}
              displayNameErr={displayNameErr}
              costErr={costErr}
              patternErr={patternErr}
              categoryErr={categoryErr}
              typeErr={typeErr}
              colorErr={colorErr}
              detailsErr={detailsErr}
              subCategoryAttributeError={subCategoryAttributeError}
              subtypeAttributeError={subtypeAttributeError}
              activateProduct={this.activateProduct}
            />

            <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />

            {product.category !== SWATCH_ACCESSORY && product.category !== SWATCH_SUIT_TUX && (
              <React.Fragment>
                <Header type={2}>{product.swatch ? 'Swatch' : 'Add a Swatch'}</Header>

                {!product.swatch && (
                  <>
                    <FormInput
                      name="swatchSearch"
                      value={swatchSearch}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.handleSwatchSearchChange(e.target.value)
                      }
                      placeholder="Search"
                      label="Search Swatches"
                      description="Search for and select a swatch to associate with this product."
                      mb={3}
                      mt={3}
                    />

                    <Box>
                      {swatchSearchResults!.map(swatch => (
                        <Button
                          key={swatch.id}
                          onClick={() => {
                            this.addSwatch(swatch);
                          }}
                          buttonType="info"
                          buttonIcon={<Plus />}
                          buttonIconPosition="right"
                          mr={2}
                          mb={2}
                        >
                          {swatch.sku} - {swatch.display_name}
                        </Button>
                      ))}
                    </Box>
                  </>
                )}

                {!!product.swatch && (
                  <Box key={product.swatch.id}>
                    <RemovableBlock
                      label={product.swatch.display_name}
                      removeFunction={() => {
                        this.removeSwatch();
                      }}
                    />
                  </Box>
                )}
              </React.Fragment>
            )}

            {!(product.category === 'Swatch (suit/tux)' || product.category === 'Swatch (accessory)') && (
              <>
                <Line my={5} lineColor="grayLight" style={{ width: '100%' }} />

                <AddMedia minDisplayIndex={calcDisplayIndex(media!)} handleNewMedia={this.handleNewMedia} />

                <MediaList media={media!} handleDelete={this.handleRemoveMedia} axis="y" lockAxis="y" />
              </>
            )}
          </Column>
        </Row>
      </Container>
    );
  }
}

export default ProductForm;
