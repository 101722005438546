import React, { Component } from 'react';
import { DropzoneComponent, InitCallback, ErrorCallback } from 'react-dropzone-component';
import { Box, Header, variables } from '@generationtux/component-library';
import styled from 'styled-components';
import { Media } from '../../../types';
import { isVideo, dropzoneConfig, djsImageConfig, djsVideoConfig } from '../../../helpers/Helpers';

import 'dropzone/dist/min/dropzone.min.css';
import 'react-dropzone-component/styles/filepicker.css';

const StyledDropzoneWrapper = styled(Box)`
  div.filepicker {
    background-color: ${props => variables(props).grayLighter};
  }
`;
interface Props {
  handleNewMedia: (media: Media) => void;
  init: InitCallback;
}

interface State {
  param: string;
}

class UploadMedia extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      param: 'image',
    };
  }

  handleSuccess = (file: string, response: Media) => {
    const media = {
      file,
      description: response.description,
      created_by: response.created_by,
      hosted_content_id: response.hosted_content_store_id,
      url: response.url,
    };

    this.props.handleNewMedia(media);
  };

  handleError: ErrorCallback = (file, xhr) => {
    if (xhr instanceof Response && xhr.status && xhr.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
  };

  handleAdding = (file: File) => {
    if (file && file.type && isVideo(file.type)) {
      return this.setState({ param: 'video' });
    }
    return this.setState({ param: 'image' });
  };

  render() {
    const { param } = this.state;

    return (
      <Box mb={5}>
        <Header type={3} size={4} mb={3} textTransform="none">
          Upload Media
        </Header>
        <StyledDropzoneWrapper>
          <DropzoneComponent
            config={dropzoneConfig}
            djsConfig={param === 'image' ? djsImageConfig : djsVideoConfig}
            eventHandlers={{
              error: this.handleError,
              success: this.handleSuccess,
              addedfile: this.handleAdding,
              init: this.props.init || null,
            }}
          />
        </StyledDropzoneWrapper>
      </Box>
    );
  }
}

export default UploadMedia;
